import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    position: relative;

    .slick-track,
    .slick-list {
      display: flex;
    }
    .slick-slide > div {
      margin: 0 1rem;
      flex: 1 0 auto;
      height: 100%;

      img {
        border-radius: 1.6rem;
      }
    }
    .slick-list {
      margin: 0 -${theme.spacings.xxsmall};
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;

      margin-top: 45vw;
      min-height: 4.5rem;

      li {
        margin: 0 0.7rem;
        list-style: none;

        button {
          cursor: pointer;
          height: 2rem;
          width: 2rem;
          border-radius: 5rem;
          background-color: #df7eb7;
          border: none;
          font-size: 0;
          transition: 0.3s;
        }

        &.slick-active {
          button {
            height: 4rem;
            width: 2rem;
            background-color: ${theme.colors.darkPink};
            transition: 0.3s;
          }
        }

        &::marker {
          content: none;
        }
      }
    }

    ${media.greaterThan('medium')`

      .slick-slider {
        padding: 0 10vw;
      }

      .slick-list {
        height: 100%;
        margin: 0;
      }

      .slick-track {
        padding: 2vw 0;
        height: 100%;
        width: 100%;
      }

      .slick-slide > div {
        margin: 0rem;
        padding: 0 1rem;
      }

      .slick-slide {
        transition: 0.5s;
      }
      
      .slick-active {
        z-index: 10;
        transform: scale(1.3);
        transition: 0.5s;
      }

      .slick-dots {
        position: relative;
        margin-top: 12vw;
        z-index: 10;
      }
    `}
  `}
`

export const Text = styled.div`
  width: 90%;
  max-width: 70rem;
  min-height: 20rem;

  bottom: 10vw;
  left: 0;
  right: 0;
  margin: 0 auto;

  position: absolute;

  text-align: center;

  p {
    margin: 2rem 0;
  }

  ${media.greaterThan('medium')`
    bottom: 3vw;
    min-height: 17.5rem;
  `}
`
