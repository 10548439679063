import * as S from './style'
import { CloseO } from '@styled-icons/evil/CloseO'
import { useEffect, useState } from 'react'

import logoTop from 'assets/img/next-step/logo-top.png'
import logoBottom from 'assets/img/next-step/logo-bottom.webp'
import logoGlam from 'assets/img/logo-glamstar.webp'
import step1 from 'assets/img/next-step/step1.webp'
import step2 from 'assets/img/next-step/step2.webp'
import step3 from 'assets/img/next-step/step3.webp'
import icon10k from 'assets/img/next-step/icon-10k.png'
import pyramid from 'assets/img/next-step/pyramid.png'
import number1 from 'assets/img/next-step/card-bottom1.webp'
import number2 from 'assets/img/next-step/card-bottom2.webp'
import number3 from 'assets/img/next-step/card-bottom3.webp'
import number4 from 'assets/img/next-step/card-bottom4.webp'

import NextStepShape from 'components/NextStepShape'
import NextStepTopSlider from 'components/NextStepTopSlider'
import NextStepCardsTop from 'components/NextStepCardsTop'
import NextStepCardsBottom from 'components/NextStepCardsBottom'
import NextStepBottomSlider from 'components/NextStepBottomSlider'
import Button from 'components/Button'
import NextStepVideosSlider from 'components/NextStepVideosSlider'
import { useBreakpoint } from 'hooks/useBreakpoint'

export default function NextStep() {
  const { isMobile } = useBreakpoint(768)

  const [showModal, setShowModal] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <S.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0 }}
    >
      <S.Banner>
        <img className="logo-top" src={logoTop} alt="seja a próxima glamstar" />
        {!isMobile && (
          <img
            className="logo-glam"
            src={logoGlam}
            alt="seja a próxima glamstar"
          />
        )}
      </S.Banner>

      <S.StepsContainer>
        <NextStepShape
          className="top-shape"
          color="white"
          topMobile={isMobile}
        />
        <NextStepShape color={undefined} topDesk={!isMobile} />

        {isMobile ? (
          <NextStepTopSlider />
        ) : (
          <div className="cards-container">
            <NextStepCardsTop
              image={step1}
              text="Inscrição inicial pelo formulário de apresentação."
            />
            <div className="dash" />
            <NextStepCardsTop
              image={step2}
              text="Ter no mínimo 10 mil seguidores nas redes sociais Instagram, TikTok e/ou YouTube."
            />
            <div className="dash" />
            <NextStepCardsTop
              image={step3}
              text="Passar pela avaliação qualitativa do nosso time, que vai analisar o engajamento dos seus seguidores e os seus conteúdos."
            />
          </div>
        )}

        <div className="text">
          <h1>Você tem muito potencial para chegar lá!</h1>
          <p>
            Ao ser aprovada nas outras duas etapas, além de garantir sua vaga
            para o Glamstar, você automaticamente se torna uma Glampartner -
            microinfluenciadora parceira da Glambox - e pode ter de 50% a 100%
            de desconto na assinatura mensal, remuneração por vendas com cupom
            de desconto exclusivo, publis de marcas queridinhas no mercado e
            muito mais!
          </p>
        </div>
        <NextStepShape
          className="bottom-shape"
          upSideDown
          color="white"
          topDesk={!isMobile}
        />
        <NextStepShape
          className="bottom-shape"
          color="white"
          upSideDown
          topMobile={isMobile}
        />
      </S.StepsContainer>

      <S.WhatHappens>
        <section className="content">
          <div>
            <p>
              Nesse momento você não tem 10 mil seguidores em suas redes
              sociais, por isso ainda não avançou para a terceira etapa de
              avaliação. Mas fique tranquila! A Glambox vai te ajudar a chegar
              lá!
            </p>
            <p>
              Você tem até 13 de fevereiro para se preparar melhor, conquistar
              novos seguidores e se inscrever novamente.
            </p>
            <h1>
              E nós separamos algumas dicas para o seu desenvolvimento como
              influenciadora digital!
            </h1>
          </div>
          <img src={icon10k} alt="glamgirl com produtos da caixa Glambox" />
        </section>

        <NextStepShape
          className="bottom-shape"
          color="lightPink"
          upSideDown
          topMobile={isMobile}
        />
        <NextStepShape
          className="bottom-shape"
          upSideDown
          color="lightPink"
          topDesk={!isMobile}
        />
      </S.WhatHappens>

      <S.WhatToDo>
        <S.PyramidContainer>
          <div className="text">
            <h1>Para ser aprovada, foque no objetivo!</h1>
            <p>
              Vamos trabalhar juntas para te desenvolver com foco em alcançar os
              10 mil seguidores nas redes sociais: Instagram, TikTok e/ou
              YouTube. Sempre garantindo que seu público seja qualificado e
              engajado com seu conteúdo!
            </p>
          </div>
          <img
            src={pyramid}
            alt="pirâmide de influenciadoras"
            className="pyramid"
          />
        </S.PyramidContainer>

        <S.SliderContainer>
          <div className="text">
            <h1>
              Para acelerar seu crescimento como influenciadora, seja uma
              assinante Glambox!
            </h1>
            <p>
              Você pode assinar a Glambox e começar na pirâmide de influência
              como uma GlamGirl, aproveitando benefícios incríveis, como:
            </p>
          </div>
          {isMobile ? (
            <NextStepBottomSlider />
          ) : (
            <div className="cards-container">
              <NextStepCardsBottom
                image={number1}
                title="Recebidos Todo Mês!"
                text="Você recebe todo mês uma box com R$220 em produtos e ainda tem descontos e frete grátis na Loja da Glambox. <br/> É a oportunidade perfeita de gravar conteúdos criativos mostrando seus “recebidos” nas redes sociais."
              />
              <NextStepCardsBottom
                image={number2}
                title="Conteúdos Exclusivos"
                text="Acesse tutoriais e cursos variados sobre lifestyle, beleza e o melhor: <b>como bombar o seu Instagram para alavancar sua carreira de influencer.</b>"
                video="https://player.vimeo.com/video/475214752?quality=540p"
              />
              <NextStepCardsBottom
                image={number3}
                title="Participação em nosso SUPER Evento!"
                text="As 10 GlamGirls mais engajadas com os challenges do Glamstar serão selecionadas para participar do nosso evento em São Paulo. As 5 melhores terão tudo pago por nós!"
              />
              <NextStepCardsBottom
                image={number4}
                title="Glamstar em <br/> Primeira Mão!"
                text="Mesmo como GlamGirl, você pode participar de todos os challenges da competição. Diferente das inscrições oficiais, você não ganha a competição, mas você ganha Glampoints e constrói sua presença nas redes sociais para participar do Glamstar 2023!"
              />
            </div>
          )}
          <h1 className="bottom-title">
            Gostou das dicas? Então vem ser uma GlamGirl, parte do maior clube
            de beleza da América Latina!
          </h1>
        </S.SliderContainer>
        <NextStepShape
          className="bottom-shape"
          color="white"
          upSideDown
          topMobile={isMobile}
        />
        <NextStepShape
          className="bottom-shape"
          upSideDown
          color="white"
          topDesk={!isMobile}
        />
      </S.WhatToDo>

      <S.SignUp>
        <section className="content">
          <img src={logoBottom} alt="Assine já e ganhe R$60,00 off" />
          <div className="text-cta">
            <p>
              Queremos te presentear com um cupom de R$80 de desconto na
              assinatura anual, para você se tornar uma GlamGirl e ter acesso à
              benefícios exclusivos para se desenvolver como influenciadora
              digital!
            </p>
            <a
              data-sub="2"
              target="_blank"
              rel="noreferrer"
              href="https://glambox.com.br/clube/checkout/2"
            >
              <Button color="red">EU QUERO</Button>
            </a>
            <p>
              Cupom: <b>STAR1</b>
            </p>
          </div>
        </section>
        <NextStepShape
          className="bottom-shape"
          upSideDown
          color="lightPink"
          topDesk={!isMobile}
        />
        <NextStepShape
          className="bottom-shape"
          color="lightPink"
          upSideDown
          topMobile={isMobile}
        />
      </S.SignUp>

      <S.VideosContainer>
        <div className="text">
          <h1>Eu preciso assinar a Glambox para poder me desenvolver?</h1>
          <p>
            Claro que não! Só acreditamos que uma assinatura ativa te prepara
            melhor. Mas de qualquer forma queremos ajudar você a chegar lá, por
            isso preparamos os conteúdos abaixo para te ajudar a alcançar os
            critérios de aprovação e se tornar uma Glampartner!
          </p>
        </div>
        <NextStepVideosSlider
          playVideo={(video: string) => setShowModal(video)}
        />
        <div className="text">
          <h1>
            Continue tentando! <br /> Você pode ser a próxima super
            influenciadora do Brasil!
          </h1>
        </div>
      </S.VideosContainer>
      {showModal && (
        <S.Modal>
          <CloseO onClick={() => setShowModal('')} />
          <div className="content">
            <img src={'https://via.placeholder.com/140x80'} alt="140x100" />
            <iframe
              width="956"
              height="538"
              src={showModal}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </S.Modal>
      )}
    </S.Wrapper>
  )
}
