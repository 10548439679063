import styled from 'styled-components'

export const Container = styled.footer`
  display: flex;
  flex-direction: row;
  background-color: #343434;
  padding: 0 6%;
  color: #fff;

  ul {
    margin: 0;
    padding: 0;

    &:before {
      content: attr(aria-label);
      font-size: 1.4rem;
      letter-spacing: 0.03em;
      font-weight: 600;
    }
  }

  ul li a {
    text-decoration: none;
    color: inherit;
    padding-left: 0.8rem;
    font-size: 12px;
    font-weight: 200;
    letter-spacing: 0.03em;
    line-height: 12px;
    filter: brightness(80%);

    &:hover {
      filter: brightness(130%);
    }
  }

  li {
    list-style: none;
    line-height: 2rem;
  }
`

export const LeftSide = styled.div`
  width: 60%;
`

export const RightSide = styled.div`
  width: 40%;
  padding-left: 2rem;
`

export const LeftHeader = styled.div`
  padding: 1.5rem 0;
`

export const RightHeader = styled.div`
  padding: 1.5rem 2rem;
`

export const LeftContent = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 35%);

  .div-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.2rem;

    > li a {
      margin-right: 0.5rem;
    }
  }

  .seguranca li img {
    margin-right: 1rem;
  }
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2.5rem;
  border-left: solid 2px #fff;

  > div {
    display: flex;
    flex-direction: row;
    margin-top: 0.2rem;
  }
`

export const LeftFooter = styled.div`
  padding: 3rem 0;
  line-height: 12px;

  > small {
    font-size: 12px;
  }
`

// !== MOBILE !== //

export const ContainerMobile = styled.div`
  background-color: #343434;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  color: #fff;

  ul li a {
    text-decoration: none;
    color: inherit;
    padding-left: 0.8rem;
    font-size: 12px;
    font-weight: 200;
    letter-spacing: 0.03em;
    line-height: 12px;
  }

  li {
    list-style: none;
    line-height: 1.8rem;
  }

  img {
    margin: 1.5rem 0 1rem 0;
  }

  > span {
    padding: 1.2rem 1rem;
    font-size: 12px;
    line-height: 2rem;
    text-align: center;
  }
`

export const SelectMobile = styled.div`
  border: solid 1px #fff;
  padding: 1rem;
  margin: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SelectTitleMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const SelectListMobile = styled.ul`
  margin-top: 0.5rem;

  li {
    margin: 1.5rem 0;
  }

  > li a {
    padding-left: 0.8rem;
    font-size: 12px;
    font-weight: 200;
    letter-spacing: 0.03em;
  }

  .div-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0.2rem;

    > li a {
      margin-right: 0.5rem;

      > img {
        margin: auto;
      }
    }
  }
`
