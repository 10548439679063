import * as S from './style'

export type LastEditionProps = {
  image: string
  playerSize?: 'large' | 'small'
  callBack: () => void
}

export default function LastEditionVideos({
  image,
  playerSize,
  callBack
}: LastEditionProps) {
  return (
    <>
      <S.Wrapper playerSize={playerSize}>
        <div className="videos_page">
          <section>
            <svg
              id="play"
              onClick={callBack}
              width="56"
              height="56"
              viewBox="0 0 56 56"
            >
              <path
                d="M28 0C22.4621 0 17.0486 1.64217 12.444 4.71885C7.83947 7.79553 4.25064 12.1685 2.13139 17.2849C0.012132 22.4012 -0.542361 28.0311 0.538025 33.4625C1.61841 38.894 4.28515 43.8831 8.20102 47.799C12.1169 51.7149 17.106 54.3816 22.5375 55.462C27.9689 56.5424 33.5988 55.9879 38.7151 53.8686C43.8315 51.7494 48.2045 48.1605 51.2812 43.556C54.3578 38.9514 56 33.5379 56 28C56 20.5739 53.05 13.452 47.799 8.20101C42.548 2.94999 35.4261 0 28 0ZM21.5385 40.9231V15.0769L38.7692 28L21.5385 40.9231Z"
                fill="#ffffff"
              />
            </svg>
            {/* <svg
              id="play"
              onClick={callBack}
              viewBox="0 0 163 163"
              version="1.1"
              xmlns="	https://s3.amazonaws.com/Glambox.Content.MediaObject/MediaFile_21411.png"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <g fill="none">
                <g transform="translate(2.000000, 2.000000)" strokeWidth="4">
                  <path
                    d="M10,80 C10,118.107648 40.8923523,149 79,149 L79,149 C117.107648,149 148,118.107648 148,80 C148,41.8923523 117.107648,11 79,11"
                    id="lineOne"
                  ></path>
                  <path
                    d="M105.9,74.4158594 L67.2,44.2158594 C63.5,41.3158594 58,43.9158594 58,48.7158594 L58,109.015859 C58,113.715859 63.4,116.415859 67.2,113.515859 L105.9,83.3158594 C108.8,81.1158594 108.8,76.6158594 105.9,74.4158594 L105.9,74.4158594 Z"
                    id="triangle"
                  ></path>
                  <path
                    d="M159,79.5 C159,35.5933624 123.406638,0 79.5,0 C35.5933624,0 0,35.5933624 0,79.5 C0,123.406638 35.5933624,159 79.5,159 L79.5,159"
                    id="lineTwo"
                  ></path>
                </g>
              </g>
            </svg> */}
            <img src={image} />
          </section>
        </div>
      </S.Wrapper>
    </>
  )
}
