import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    .link {
      color: ${theme.colors.red};
      font-weight: ${theme.font.semiBold};
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  `}
`
export const Banner = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.purple};
    height: 50vw;

    img {
      margin: 3vw 0 0 12vw;
      width: 15rem;
    }

    ${media.greaterThan('medium')`
      height: 20vw;

      img {
        margin: 3vw 0 0 12vw;
        width: 12vw;
      }
    `}
  `}
`

export const FaqContainer = styled.section`
  position: relative;
  padding: 10vw 0;

  .link {
    text-align: center;
  }

  .title {
    padding: 2rem;
    text-align: center;

    h1 {
      margin-bottom: 3rem;
      max-width: 35rem;
    }
  }

  .shape {
    position: absolute;
    top: -20vw;
  }

  .questions {
    padding: 0 2rem;
  }

  ${media.greaterThan('medium')`
    .shape {
      top: -9vw;
    }

    .title {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      margin: 0 auto;
      max-width: 110rem;

      h1 {
        margin: 0;
        text-align: left;
      }

      .link {
        margin-bottom: 1rem;
      }
    }

    .questions {
      max-width: 110rem;
      margin: 0 auto;

      .my-masonry-grid {
        display: flex;
        margin-left: -3rem; /* gutter size offset */
        width: auto;
      }
      .my-masonry-grid_column {
        padding-left: 30px; /* gutter size */
        background-clip: padding-box;
      }

      padding: 0 2rem;
    }
  `}
`
