import { ThemeProvider } from 'styled-components'
import Router from 'router'
import theme from 'styles/theme'
import GlobalStyles from 'styles/global'
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Router />
        <GlobalStyles />
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
