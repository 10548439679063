import { Switch, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import Home from 'templates/Home'
import Compliment from 'templates/Compliment'
import Subscription from 'templates/Subscription'
import Approved from 'templates/Approved'
import Old2017 from 'templates/Old2017'
import Footer from 'components/Footer'
import NextStep from 'templates/NextStep'
import Faq from 'templates/Faq'

function Router(): JSX.Element {
  return (
    <AnimatePresence exitBeforeEnter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/parabens" component={Compliment} />
        <Route exact path="/inscricoes" component={Subscription} />
        <Route exact path="/proxima-fase" component={NextStep} />
        {/* <Route exact path="/inscreva-se" component={Approved} /> */}
        <Route exact path="/2017" component={Old2017} />
        <Route exact path="/faq" component={Faq} />
        <Route path="/">
          <h1>Ops! Essa página não existe</h1>
        </Route>
      </Switch>
      <Footer />
    </AnimatePresence>
  )
}

export default Router
