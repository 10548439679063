import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 100%;
    }

    p {
      min-height: 14rem;
      max-height: 14rem;
      text-align: center;
      font-size: ${theme.font.sizes.small};
      margin-top: 1rem;
    }

    ${media.greaterThan('medium')`
      width: 20%;
    `}
  `}
`
