import * as S from './style'
import { PlusMedical } from '@styled-icons/boxicons-regular/PlusMedical'
import { AnimatePresence } from 'framer-motion'
import { ReactElement } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

type FaqProps = {
  question: ReactElement
  answer: ReactElement
  openAnswer: boolean
  callBack: () => void
}

const variants = {
  closed: { height: 0, opacity: 0, transition: { duration: 0.3 } },
  opened: {
    height: 'fit-content',
    opacity: 1,
    transition: { duration: 0.3 }
  }
}

export default function FaqElement({
  question,
  answer,
  openAnswer,
  callBack
}: FaqProps) {
  return (
    <S.Wrapper initial={{ x: '-100vw' }} animate={{ x: 0 }}>
      <S.Question
        animate={
          openAnswer
            ? {
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px'
              }
            : {
                borderBottomLeftRadius: '9px',
                borderBottomRightRadius: '9px'
              }
        }
        onClick={callBack}
      >
        {question}
        {openAnswer ? (
          <FiChevronUp size={24} color="#fff" />
        ) : (
          <FiChevronDown size={24} color="#fff" />
        )}
      </S.Question>
      <AnimatePresence>
        {openAnswer && (
          <S.Answer
            variants={variants}
            initial={'closed'}
            animate={'opened'}
            exit={'closed'}
          >
            {answer}
          </S.Answer>
        )}
      </AnimatePresence>
    </S.Wrapper>
  )
}
