import { useState } from 'react'
import {
  RiFacebookCircleLine,
  RiYoutubeLine,
  RiInstagramLine
} from 'react-icons/ri'
import { ImCreditCard, ImBarcode } from 'react-icons/im'
import { ChevronUp } from '@styled-icons/bootstrap/ChevronUp'
import { ChevronDown } from '@styled-icons/bootstrap/ChevronDown'
import { useBreakpoint } from 'hooks/useBreakpoint'
import glamboxLogo from 'assets/img/logo-full.png'
import connectLogo from 'assets/img/logo-connect.png'
import Pix from '../../assets/img/footer/pix.svg'
import PagarMe from '../../assets/img/footer/pagarme.png'
import Ssl from '.../../assets/img/footer/ssl.png'
import GoogleSafe from '../../assets/img/footer/google-safe.png'
import RA from '../../assets/img/footer/RA.png'
import {
  LinksUniversoGlam,
  LinksInstitucional,
  LinksMens,
  LinksB4a,
  LinksPodemosTeAjudar
} from './linksFooter'
import * as S from './styles'

const Footer: React.FC = () => {
  const { isMobile } = useBreakpoint(700)
  const [dropDownOpenUniverso, setDropdownOpenUniverso] = useState(false)
  const [dropDownOpenInstitucional, setDropdownOpenInstitucional] =
    useState(false)
  const [dropDownOpenPodemos, setDropdownOpenPodemos] = useState(false)
  const [dropDownOpenRedes, setDropdownOpenRedes] = useState(false)
  const [dropDownOpenPague, setDropdownOpenPague] = useState(false)
  const [dropDownOpenSeguranca, setDropdownOpenSeguranca] = useState(false)
  const [dropDownOpenReputacao, setDropdownOpenReputacao] = useState(false)
  const [dropDownOpenMens, setDropdownOpenMens] = useState(false)
  const [dropDownOpenB4a, setDropdownOpenB4a] = useState(false)

  function handleDropdownUniverso() {
    setDropdownOpenUniverso(!dropDownOpenUniverso)
  }

  function handleDropdownInstitucional() {
    setDropdownOpenInstitucional(!dropDownOpenInstitucional)
  }

  function handleDropdownPodemos() {
    setDropdownOpenPodemos(!dropDownOpenPodemos)
  }

  function handleDropdownRedes() {
    setDropdownOpenRedes(!dropDownOpenRedes)
  }
  function handleDropdownPague() {
    setDropdownOpenPague(!dropDownOpenPague)
  }
  function handleDropdownSeguranca() {
    setDropdownOpenSeguranca(!dropDownOpenSeguranca)
  }

  function handleDropdownReputacao() {
    setDropdownOpenReputacao(!dropDownOpenReputacao)
  }

  function handleDropdownMens() {
    setDropdownOpenMens(!dropDownOpenMens)
  }

  function handleDropdownB4a() {
    setDropdownOpenB4a(!dropDownOpenB4a)
  }

  return (
    <>
      {!isMobile ? (
        <S.Container>
          <S.LeftSide>
            <S.LeftHeader>
              <img
                src={glamboxLogo}
                height={46}
                width={216}
                alt="Logo Glambox"
              />
            </S.LeftHeader>

            <S.LeftContent>
              <ul aria-label="Universo Glam">
                {LinksUniversoGlam.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url} target="blank">
                        {link.title}
                      </a>
                    </li>
                  )
                })}
              </ul>

              <ul aria-label="Institucional">
                {LinksInstitucional.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url} target="blank">
                        {link.title}
                      </a>
                    </li>
                  )
                })}
              </ul>

              <div>
                <ul aria-label="Podemos te ajudar?">
                  {LinksPodemosTeAjudar.map((link) => {
                    return (
                      <li key={link.title}>
                        <a href={link.url} target="blank">
                          {link.title}
                        </a>
                      </li>
                    )
                  })}
                </ul>

                <ul aria-label="Redes Sociais">
                  <div className="div-icons">
                    <li>
                      <a
                        href="https://www.facebook.com/glambox.br"
                        target="blank"
                      >
                        <RiFacebookCircleLine size={20} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/user/glamboxbrasil"
                        target="blank"
                      >
                        <RiYoutubeLine size={20} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/glamboxbrasil/"
                        target="blank"
                      >
                        <RiInstagramLine size={20} />
                      </a>
                    </li>
                  </div>
                </ul>
              </div>

              {/* <ul aria-label="Pague com">
                <div className="div-icons">
                  <li>
                    <a>
                      <ImCreditCard size={20} />
                    </a>
                  </li>
                  <li>
                    <a>
                      <ImBarcode size={20} />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={Pix} alt="pix" height={18} />
                    </a>
                  </li>
                </div>
              </ul>

              <ul aria-label="Reputação">
                <div>
                  <li>
                    <img src={RA} alt="Selo Reclame Aqui" height={32} />
                  </li>
                </div>
              </ul>

              <ul aria-label="Segurança">
                <div className="div-icons seguranca">
                  <li>
                    <img src={GoogleSafe} alt="Selo Google Safe" height={22} />
                  </li>
                  <li>
                    <img src={PagarMe} alt="Selo Pagar Me" height={30} />
                  </li>
                  <li>
                    <img src={Ssl} alt="Selo SSL" height={30} />
                  </li>
                </div>
              </ul> */}
            </S.LeftContent>
            <S.LeftFooter>
              <small>
                B4A Serviços de tecnologia e comércio s.a. Rua Pequetita, 111 -
                Vila Olímpia - São Paulo - SP / Brasil
              </small>
            </S.LeftFooter>
          </S.LeftSide>

          <S.RightSide>
            <S.RightHeader>
              <img
                src={connectLogo}
                height={46}
                width={216}
                alt="Logo Powered by B4A Connect"
              />
            </S.RightHeader>

            <S.RightContent>
              <ul aria-label="Universo Men's" className="universo-mens">
                {LinksMens.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url}>{link.title}</a>
                    </li>
                  )
                })}
              </ul>

              <ul aria-label="B4A" className="b4a">
                {LinksB4a.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url}>{link.title}</a>
                    </li>
                  )
                })}
              </ul>
              <div></div>
            </S.RightContent>
          </S.RightSide>
        </S.Container>
      ) : (
        <S.ContainerMobile>
          <img src={glamboxLogo} alt="Logo Glambox" height={36} />

          <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownUniverso}>
              <p>Universo Glam</p>
              {!dropDownOpenUniverso ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenUniverso && (
              <S.SelectListMobile>
                {LinksUniversoGlam.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url} target="blank">
                        {link.title}
                      </a>
                    </li>
                  )
                })}
              </S.SelectListMobile>
            )}
          </S.SelectMobile>

          <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownInstitucional}>
              <p>Institucional</p>
              {!dropDownOpenInstitucional ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenInstitucional && (
              <S.SelectListMobile>
                {LinksInstitucional.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url} target="blank">
                        {link.title}
                      </a>
                    </li>
                  )
                })}
              </S.SelectListMobile>
            )}
          </S.SelectMobile>

          <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownPodemos}>
              <p>Podemos te ajudar?</p>
              {!dropDownOpenPodemos ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenPodemos && (
              <S.SelectListMobile>
                {LinksPodemosTeAjudar.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url} target="blank">
                        {link.title}
                      </a>
                    </li>
                  )
                })}
              </S.SelectListMobile>
            )}
          </S.SelectMobile>

          <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownRedes}>
              <p>Redes Sociais</p>
              {!dropDownOpenRedes ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenRedes && (
              <S.SelectListMobile>
                <div className="div-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/glambox.br"
                      target="blank"
                    >
                      <RiFacebookCircleLine size={25} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/user/glamboxbrasil"
                      target="blank"
                    >
                      <RiYoutubeLine size={25} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/glamboxbrasil/"
                      target="blank"
                    >
                      <RiInstagramLine size={25} />
                    </a>
                  </li>
                </div>
              </S.SelectListMobile>
            )}
          </S.SelectMobile>

          {/* <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownPague}>
              <p>Pague Com</p>
              {!dropDownOpenPague ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenPague && (
              <S.SelectListMobile>
                <div className="div-icons">
                  <li>
                    <a>
                      <ImCreditCard size={25} />
                    </a>
                  </li>
                  <li>
                    <a>
                      <ImBarcode size={25} />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={Pix} alt="pix" height={21} />
                    </a>
                  </li>
                </div>
              </S.SelectListMobile>
            )}
          </S.SelectMobile>

          <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownSeguranca}>
              <p>Segurança</p>
              {!dropDownOpenSeguranca ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenSeguranca && (
              <S.SelectListMobile>
                <div className="div-icons">
                  <li>
                    <a>
                      <img
                        src={GoogleSafe}
                        alt="Selo Google Safe"
                        height={28}
                      />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={PagarMe} alt="Selo Pagar Me" height={35} />
                    </a>
                  </li>
                  <li>
                    <a>
                      <img src={Ssl} alt="Selo SSL" height={35} />
                    </a>
                  </li>
                </div>
              </S.SelectListMobile>
            )}
          </S.SelectMobile>

          <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownReputacao}>
              <p>Reputação</p>
              {!dropDownOpenReputacao ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenReputacao && (
              <S.SelectListMobile>
                <div>
                  <li>
                    <a>
                      <img src={RA} alt="Selo Reclame Aqui" height={40} />
                    </a>
                  </li>
                </div>
              </S.SelectListMobile>
            )}
          </S.SelectMobile> */}

          <img src={connectLogo} alt="Logo Connect B4A" height={36} />

          <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownMens}>
              <p>{`Universo Men's`}</p>
              {!dropDownOpenMens ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenMens && (
              <S.SelectListMobile>
                {LinksMens.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url} target="blank">
                        {link.title}
                      </a>
                    </li>
                  )
                })}
              </S.SelectListMobile>
            )}
          </S.SelectMobile>

          <S.SelectMobile>
            <S.SelectTitleMobile onClick={handleDropdownB4a}>
              <p>B4A</p>
              {!dropDownOpenB4a ? (
                <ChevronDown size={20} />
              ) : (
                <ChevronUp size={20} />
              )}
            </S.SelectTitleMobile>
            {!!dropDownOpenB4a && (
              <S.SelectListMobile>
                {LinksB4a.map((link) => {
                  return (
                    <li key={link.title}>
                      <a href={link.url} target="blank">
                        {link.title}
                      </a>
                    </li>
                  )
                })}
              </S.SelectListMobile>
            )}
          </S.SelectMobile>

          <span>
            B4A Serviços de tecnologia e comércio s.a. Rua Pequetita, 111 - Vila
            Olímpia - São Paulo - SP / Brasil
          </span>
        </S.ContainerMobile>
      )}
    </>
  )
}

export default Footer
