import { useEffect, useState } from 'react'

export function useBreakpoint(breakpoint = 425) {
  const [innerWidth, setInnerWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const { innerWidth: width } = window

    setInnerWidth(width)

    window.addEventListener('resize', resize)
    if (width <= breakpoint) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [innerWidth, isMobile, breakpoint])

  function resize() {
    const { innerWidth: width } = window
    setInnerWidth(width)
  }

  return { isMobile, innerWidth }
}
