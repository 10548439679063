import * as S from './style'

export type CardsProps = {
  image: string
  text: string
  title: string
  isSlideItem?: boolean
  video?: string
}

export default function NextStepCardsBottom({
  image,
  text,
  title,
  isSlideItem,
  video
}: CardsProps) {
  return (
    <S.Wrapper isSlideItem={isSlideItem}>
      <img src={image} alt={image} />
      <h3 dangerouslySetInnerHTML={{ __html: title }} />
      <p dangerouslySetInnerHTML={{ __html: text }} />
      {video && <iframe allowFullScreen src={video} frameBorder="0"></iframe>}
    </S.Wrapper>
  )
}
