type MidShapeProps = {
  mobile: boolean
}

export default function MidShape({ mobile }: MidShapeProps) {
  return (
    <>
      {mobile && (
        <svg viewBox="60 25 400 700" xmlSpace="preserve">
          <path
            d="M52.8 34.7C198.5 33 276.6 43 321.3 53.1c6.2 1.4 28.6 6.6 60.8 13.3C435 77.5 477.9 85.2 505 89.8v621.9L57.4 683.3 52.8 34.7z"
            fill="#a044f1"
          />
          <path
            fill="#FF0117"
            d="M57.2 648.6c58.7 13.5 124.6 21.9 210.4 30.9 89.9 9.4 170.5 3.2 237.4-3.6v35.8c-58.1 7.5-111.5 7.5-148.1 7.2-70.7-.4-125.8-7.8-180.2-15.3-48.8-6.7-89.5-14.2-119.2-20.3l-.3-34.7z"
          />
          <g>
            <path
              fill="#FF0117"
              d="M209.1 38.3s-67.4-8.5-156.3-11.5v9l156.3 2.5z"
            />
          </g>
        </svg>
      )}

      {!mobile && (
        <svg viewBox="30 90 1400.1 500" xmlSpace="preserve">
          <path
            d="M.4 204.4c40.8-22.3 101.4-51 178.9-71.5 21.4-5.7 58.8-14.6 133.7-23.7 56.3-6.8 152.2-15.9 282.8-14.1 133.8 1.8 246.1 15.3 300 22.4 75 9.9 107 17.5 184.2 23 52.2 3.7 110.8 7.9 181.7 2.6 59.2-4.5 145.1-16.9 246.3-53.7l.6 439.5-215.6 43.5-303.2-9.6-347.4-53.1-211.1-45.4-89.6-1.9L.4 515.5V204.4z"
            fill="#9f4dee"
          />
          <path
            d="M-1.1 495.9C115.1 459.3 270 430 447.2 451.5c13.4 1.6 50.3 8.1 91.4 15.4 135 24.1 181.7 38.3 264.7 53.8 36.2 6.7 134.1 23.9 256.1 30.7 89.9 5 170.4 9.9 274.9-4.3 73.9-10 134.2-25.9 175.9-38.7v30.9s-90 29.9-171.7 38.9c-37.9 4.8-92 9.9-158.2 9.9-21.8 0-38.2-.5-48.1-.9-34.8-1.2-79.1-3.8-177.5-15.9-81.4-10-122.1-15-175.8-24.9-47.7-8.9-59.3-12.8-122.9-26.2-81.8-17.2-122.8-25.6-172.8-32.7-52.4-7.5-95.2-13.5-153.9-13.8-62-.2-108.5 6.2-149.2 12-47.2 6.7-108 18.1-178 38.3l-3.2-28.1z"
            fill="#f30d28"
          />
        </svg>
      )}
    </>
  )
}
