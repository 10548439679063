import * as S from './style'
import Slider, { SliderSettings } from 'components/Slider'

import LastEditionVideos from 'components/LastEditionVideos'
import texts from './textsList'

import video1 from 'assets/img/next-step/video1.webp'
import video2 from 'assets/img/next-step/video2.webp'
import video3 from 'assets/img/next-step/video3.webp'
import video4 from 'assets/img/next-step/video4.webp'
import video5 from 'assets/img/next-step/video5.webp'
import video6 from 'assets/img/next-step/video6.webp'
import video7 from 'assets/img/next-step/video7.webp'
import { useState } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function NextStepVideosSlider({ playVideo }: any) {
  const [textsCounter, setTextsCounter] = useState(1)

  const settings: SliderSettings = {
    slidesToShow: 1,
    lazyLoad: 'progressive',
    dots: true,
    centerMode: true,
    infinite: true,
    // autoplay: true,
    // autoplaySpeed: 5000,
    centerPadding: '33%',
    arrows: false,
    touchThreshold: 100,
    beforeChange: (current, next) => setTextsCounter(next),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerPadding: '80px',
          arrows: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '10px',
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  }

  return (
    <>
      <S.Wrapper>
        <Slider settings={settings}>
          <LastEditionVideos
            callBack={() =>
              playVideo('https://www.youtube.com/embed/S4Oc58QXzEM')
            }
            image={video1}
          />
          <LastEditionVideos
            callBack={() =>
              playVideo('https://www.youtube.com/embed/vDgWku-oNCI')
            }
            image={video2}
          />
          <LastEditionVideos
            callBack={() =>
              playVideo('https://www.youtube.com/embed/xyGA6fHyidM')
            }
            image={video3}
          />
          <LastEditionVideos
            callBack={() =>
              playVideo('https://www.youtube.com/embed/ptWG_4CxgyA')
            }
            image={video4}
          />
          <LastEditionVideos
            callBack={() =>
              playVideo('https://www.youtube.com/embed/f6s_pKJYcyU')
            }
            image={video5}
          />
          <LastEditionVideos
            callBack={() =>
              playVideo('https://www.youtube.com/embed/RxwGWWK-BPU')
            }
            image={video6}
          />
          <LastEditionVideos
            callBack={() =>
              playVideo('https://www.youtube.com/embed/--bL32p9EiU')
            }
            image={video7}
          />
        </Slider>
        <S.Text>
          <h3>{texts[textsCounter].title}</h3>
          <p>{texts[textsCounter].description}</p>
        </S.Text>
      </S.Wrapper>
    </>
  )
}
