export default [
  {
    title: 'Dica de Influencer',
    description:
      'A maior competição de influencers do Brasil é exclusiva para glampartners! Dá uma olhada nas melhores dicas com a vencedora do Glamstar de 2016 para arrasar na sua inscrição!'
  },
  {
    title: 'Tutorial de Make',
    description:
      'Ahhh, o bom e velho tutorial! Para garantir um conteúdo incrível nas suas redes, não pode faltar um tutorial de make, viu?'
  },
  {
    title: 'Rotina de Skincare',
    description:
      'O skincare bombou e você não pode deixar de criar sua rotina e mostrar pro mundo. Eles são um sucesso à parte!'
  },
  {
    title: 'Unboxing Take a Break Daiene Calmon',
    description:
      'E uma boa Glampartner sempre arrasa nos unboxings!  Dá uma olhada em como fazer um bom “recebidinhos”.'
  },
  {
    title: 'Resenhas de Produtos',
    description:
      'Outro conteúdo MARA para seus seguidores são resenhas e reviews de produtos que estão sendo lançados no mercado. Hoje em dia a opinião de uma influencer vale muito para decisão de compra.'
  },
  {
    title: 'Programa Chama as Amigas',
    description:
      'Você ganha e suas amigas também! Treine a vender através do programa Chama as Amigas divulgando o seu cupom e ganhe muitos brindes.'
  },
  {
    title: 'Explicando o Universo Glam',
    description:
      'Uma Glampartner de sucesso é aquela que explica nosso universo para seus seguidores! Perfil de beleza, Glamshop, Glampoints…São muitos benefícios incríveis do clube!'
  }
]
