import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import media from 'styled-media-query'

import bannerMobile from 'assets/img/subscription/banner-mb.webp'
import bannerDesk from 'assets/img/subscription/banner-desk.webp'

type TextFieldProps = {
  error: boolean
}

export const Wrapper = styled(motion.main)`
  overflow: hidden;
`

export const Banner = styled.section`
  padding-bottom: calc(900 / 600 * 100%);

  background-image: url(${bannerMobile});
  background-size: cover;

  position: relative;

  img {
    position: absolute;
    width: 95%;
    top: 1rem;
  }

  ${media.greaterThan('medium')`
    padding-bottom: calc(859 / 1587 * 100%);

    background-image: url(${bannerDesk});

      img {
        position: absolute;
        width: 45%;

        top: 0;
        bottom: 0;
        left: 0;
        right: 25vw;

        margin: auto;
      }
  `}
`

export const FormContainer = styled.section`
  ${({ theme }) => css`
    margin-bottom: -2vw;
    padding-bottom: 10vw;
    position: relative;

    height: fit-content;

    background-color: ${theme.colors.purple};

    display: flex;
    justify-content: center;

    svg {
      position: absolute;
      margin-top: -17vw;
    }

    ${media.greaterThan('medium')`
      height: fit-content;
      svg {
        position: absolute;
        margin-top: -9vw;
      }
    `}
  `}
`

export const Form = styled.form`
  ${({ theme }) => css`
    padding: 2rem 4rem 0 4rem;
    width: 100%;
    max-width: 38rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    h1 {
      color: ${theme.colors.white};
      text-align: center;
      max-width: 50rem;
      margin-bottom: 2rem;
    }

    button {
      width: 90%;
      height: 6rem;
      margin: 0.8rem 0 4rem 0;
      border-radius: 0.8rem;
    }

    .display-flex {
      margin-top: 0.8rem;
      div {
        &:first-child {
          margin-top: -0.8rem;
        }
      }

      max-height: fit-content;
      width: 100%;
    }

    ${media.greaterThan('medium')`

      padding-top: 0;
      max-width: 40vw;
      min-width: 70rem;

      .display-flex {
        display: flex;
        gap: 2rem;

        margin-top: 0;

        div {
          &:first-child {
            margin-top: 0.8rem;
          }
        }
      }

      button {
        margin: 4rem 0 4rem 0;
      }
    `}
  `}
`

export const InputContainer = styled.div`
  width: 100%;
  margin: 0.8rem 0;
`
export const TextField = styled.input<TextFieldProps>`
  ${({ theme, error }) => css`
    width: 100%;
    height: 5rem;
    padding: 1.5rem;

    border-radius: 0.7rem;
    border: 0.1rem solid ${theme.colors.black};

    transition: 0.3s;

    ${error &&
    css`
      border: 0.2rem solid ${theme.colors.red};
      box-shadow: 0 0 0px 2px ${theme.colors.red};
    `}

    &:hover {
      border: 0.2rem solid ${theme.colors.darkPink};
      transition: 0.3s;
    }

    &:focus {
      border: 0.2rem solid ${theme.colors.darkPink};
      outline: 0;
    }
  `}
`
export const ErrorMessage = styled.p`
  ${({ theme }) => css`
    margin-top: 0.5rem;
    font-size: ${theme.font.sizes.xsmall};
    color: ${theme.colors.white};
  `}
`
export const SentMessage = styled(motion.p)`
  ${({ theme }) => css`
    margin-top: 2rem;
    color: ${theme.colors.white};
    text-align: center;
  `}
`
export const FormText = styled.p`
  ${({ theme }) => css`
    text-align: center;
    color: ${theme.colors.white};
  `}
`

export const RecaptchaContainer = styled(motion.section)`
  margin-top: 3rem 0;

  div {
    width: 32rem;
    height: fit-content;

    div {
      width: 100%;
    }
  }

  ${media.greaterThan('medium')`
    margin-top: 2rem;
    margin-bottom: -1rem;
  `}
`
