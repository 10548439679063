import * as S from './style'
import Slider, { SliderSettings } from 'components/Slider'

import LastEdition from 'components/LastEdition'

import video2016 from 'assets/img/video-2016.webp'
import video2017 from 'assets/img/video-2017.webp'

const settings: SliderSettings = {
  slidesToShow: 2,
  infinite: false,
  lazyLoad: 'progressive',
  dots: true,
  centerMode: true,
  centerPadding: '200px',
  arrows: false,
  touchThreshold: 100,
  autoplay: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: '150px',
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: '80px',
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        centerPadding: '35px',
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function VideosSlider({ playVideo }: any) {
  return (
    <>
      <S.Wrapper>
        <Slider settings={settings}>
          <LastEdition
            callBack={() =>
              playVideo('https://www.youtube.com/embed/_oyRSoT5p40')
            }
            image={video2017}
          />

          <LastEdition
            callBack={() =>
              playVideo('https://www.youtube.com/embed/4y-dDpwXdLY')
            }
            image={video2016}
          />
        </Slider>
      </S.Wrapper>
    </>
  )
}
