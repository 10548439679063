import theme from 'styles/theme'

type NextShapeProps = {
  topMobile?: boolean
  topDesk?: boolean
  upSideDown?: boolean
  color: 'lightPink' | 'white' | 'midWhite' | 'purple' | undefined
  className?: string
}

export default function NextStepShape({
  topMobile,
  topDesk,
  upSideDown,
  color,
  className
}: NextShapeProps) {
  return (
    <>
      {topMobile && (
        <svg
          className={className}
          viewBox="0 0 375 34"
          style={{ transform: upSideDown ? 'scale(-1, -1)' : 'scale(1)' }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M375 326.5L0 326.5L2.61831e-05 27C131.5 27 147.5 1.28949e-05 375 3.27835e-05L375 326.5Z"
            fill={theme.colors[color!]}
          />
        </svg>
      )}

      {topDesk && (
        <>
          {color ? (
            <svg
              className={className}
              fill={theme.colors[color]}
              style={{ transform: upSideDown ? 'scale(-1, -1)' : 'scale(1)' }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 50"
              preserveAspectRatio="none"
            >
              <path
                d="M1366 335.028L0 335.029L5.23662e-05 35.5291C677 35.529 825.5 -20.971 1366 8.52822L1366 335.028Z"
                fill="#FFDFEE"
              ></path>
            </svg>
          ) : (
            <svg
              className={className}
              viewBox="0 0 1366 50"
              style={{ transform: upSideDown ? 'scale(-1, -1)' : 'scale(1)' }}
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
            >
              <path
                d="M1366 335.028L0 335.029L5.23662e-05 35.5291C677 35.529 825.5 -20.971 1366 8.52822L1366 335.028Z"
                fill="#FFDFEE"
              />
            </svg>
          )}
        </>
      )}
    </>
  )
}
