import * as S from './style'
import { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css'

import logoGlam from 'assets/img/logo-glamstar.webp'

import FaqElement from 'components/FaqElement'
import NextStepShape from 'components/NextStepShape'

import faqList from 'templates/Home/faqList'

import { useBreakpoint } from 'hooks/useBreakpoint'
import { motion } from 'framer-motion'

export default function Faq() {
  const { isMobile } = useBreakpoint(768)
  const [openAnswer, setOpenAnswer] = useState(-1)
  const [numberOfQuestions, setNumberOfQuestions] = useState(5)

  const handleOpenAnswer = (idx: number) => {
    setOpenAnswer(idx)

    if (openAnswer == idx) {
      setOpenAnswer(-1)
    }
  }

  useEffect(() => {
    if (!isMobile) {
      setNumberOfQuestions(14)
    } else {
      setNumberOfQuestions(5)
    }
  }, [isMobile])

  return (
    <S.Wrapper>
      <S.Banner>
        <img src={logoGlam} alt="Glamstar" />
      </S.Banner>
      <S.FaqContainer>
        <NextStepShape color="white" className="shape" topMobile={isMobile} />
        <NextStepShape
          color={undefined}
          className="shape"
          topDesk={!isMobile}
        />
        <div className="title">
          <h1>Dúvidas frequentes Glamstar 2022</h1>
          <a className="link" href="/">
            Voltar para o início
          </a>
        </div>
        <div className="questions">
          {isMobile ? (
            faqList.slice(0, numberOfQuestions).map((items, idx) => {
              return (
                <>
                  <FaqElement
                    key={idx}
                    question={items.question}
                    answer={items.answer}
                    openAnswer={openAnswer == idx}
                    callBack={() => handleOpenAnswer(idx)}
                  />
                </>
              )
            })
          ) : (
            <Masonry
              breakpointCols={2}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {faqList.slice(0, numberOfQuestions).map((items, idx) => {
                return (
                  <>
                    <FaqElement
                      key={idx}
                      question={items.question}
                      answer={items.answer}
                      openAnswer={openAnswer == idx}
                      callBack={() => handleOpenAnswer(idx)}
                    />
                  </>
                )
              })}
            </Masonry>
          )}
        </div>
        {numberOfQuestions < 14 && (
          <p
            onClick={() => setNumberOfQuestions(numberOfQuestions + 5)}
            className="link"
          >
            Tenho mais dúvidas
          </p>
        )}
      </S.FaqContainer>
    </S.Wrapper>
  )
}
