import {
  createGlobalStyle,
  css,
  DefaultTheme,
  GlobalStyleComponent
} from 'styled-components'
import media from 'styled-media-query'

type GlobalStylesProps = {
  removeBg?: boolean
  theme?: DefaultTheme
}

const GlobalStyles: GlobalStyleComponent<
  GlobalStylesProps,
  DefaultTheme
> = createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    --webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ${({ theme }) => css`
    html {
      font-size: 62.5%;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    button {
      font-family: ${theme.font.family.headline};
    }

    h1 {
      font-size: 2.8rem;
      font-weight: 600;
    }

    h3 {
      font-size: ${theme.font.sizes.medium};
    }

    p,
    a {
      font-size: ${theme.font.sizes.small};
      font-weight: ${theme.font.normal};
    }

    small {
      font-size: ${theme.font.sizes.xsmall};
      line-height: 0.1rem;
    }

    body {
      font-family: ${theme.font.family.body};
      font-size: ${theme.font.sizes.medium};
    }

    ${media.greaterThan('medium')`
      h1 {
        font-size: ${theme.font.sizes.large};
      }

      p, a {
        font-size: ${theme.font.sizes.medium};
      }
    `}

    #root {
      overflow: hidden;
    }
  `}

`
export default GlobalStyles
