import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { motion } from 'framer-motion'

import lastPhaseImage from '../../assets/img/ultima-etapa.webp'
import lastPhaseImageMobile from '../../assets/img/ultima-etapa-mobile.webp'
import PinkWaveBackground from '../../assets/img/pink-wave-background.svg'

export const Wrapper = styled(motion.main)`
  overflow: hidden;
`

export const MidShapeContainer = styled.section`
  ${({ theme }) => css`
    position: relative;
    margin-top: -14vw;
    height: 175vw;
    max-height: 1120px;

    display: flex;
    justify-content: center;

    z-index: ${theme.layers.base};
    pointer-events: none;

    svg {
      position: absolute;
      min-width: 100vw;
      height: 95%;
    }

    .logo-happy {
      position: relative;
      z-index: ${theme.layers.base};

      width: 100%;
      height: 100%;

      max-width: 388px;
      max-height: 292px;

      object-fit: contain;
      padding: 0 1rem;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin-bottom: 4rem;
    }

    h1 {
      color: ${theme.colors.white};
      z-index: ${theme.layers.base};
      text-align: center;
      margin: 14vw 0 8vw;
      max-width: 90%;
    }

    ${media.greaterThan('medium')`
      margin-top: -10vw;
      height: 75vw;
      min-height: 826px;
      max-height: 1120px;

      .logo-glam {
        position: absolute;
        width: 152px;
        height: 97px;
        top: 12vw;
        bottom: 0;
        right: 0;
        left: 12vw;
        z-index: ${theme.layers.base};

      }

      div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        padding: 0 10rem;

        h1 {
          margin: auto;
          height: 100px;
          width: 40%;
          max-width: 40rem;
          color: ${theme.colors.white};
          z-index: ${theme.layers.base};
          text-align: left;
          padding: 0 16px;
        }

        .logo-happy {
          margin: 0 auto 64px;
          position: relative;
          z-index: ${theme.layers.base};
          width: 517px;
          height: 390px;
        }
      }
    `}
  `}
`
export const PhasesContainer = styled.section`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > div {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      gap: 24px;
    }

    .description {
      display: block;
      max-width: 830px;
      margin-top: 42px;

      p {
        margin: 32px 0;
        text-align: center;
      }

      a {
        color: ${theme.colors.red};
        font-weight: ${theme.font.bold};

        transition: all 0.2s ease;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  `}
`

export const PhasesContainerMobile = styled.section`
  ${({ theme }) => css`
    margin: 42px 0;

    .slick-track,
    .slick-list {
      display: flex;
    }
    .slick-slide {
        > div {
          margin: 0 ${theme.spacings.xsmall};
          flex: 1 0 auto;
          height: 100%;
        }
      }
        }
      }



      }
    }
    .slick-list {
      margin: 0 -${theme.spacings.xxsmall};
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      margin-top: 3rem;
      min-height: 4.5rem;

      li {
        margin: 0 0.7rem;

        list-style: none;

        button {
          height: 2rem;
          width: 2rem;
          border-radius: 5rem;
          background-color: ${theme.colors.lightPink};
          border: none;
          font-size: 0;
          transition: 0.3s;
        }

        &.slick-active {
          button {
            height: 4rem;
            width: 2rem;
            background-color: ${theme.colors.darkPink};
            transition: 0.3s;
          }
        }

        &::marker {
          content: none;
        }
      }
    }
  `}
`

export const DividerPhase = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.lightGray};
    border-radius: 50px;
    opacity: 1;

    align-self: center;
    margin-bottom: 35px;

    width: 100px;
    height: 6px;
  `}
`

export const Phase = styled.div`
  ${({ theme }) => css`
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 140px;
    }

    p {
      max-width: 250px;
      margin-top: 24px;

      text-align: center;
      font-weight: 300;
      font-size: ${theme.font.sizes.small};
    }

    position: relative;
  `}
`

export const LastPhaseContainer = styled.section`
  ${() => css`
    width: 100%;
    height: 772px;
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 64px;

    background-image: url(${PinkWaveBackground});
    background-repeat: no-repeat;
    background-size: 100%;

    .image-content {
      position: relative;
      width: 964px;
      height: 616px;

      background-image: url(${lastPhaseImage});
      background-position: center;
      background-size: contain;
    }
  `}
`

export const LastPhaseContainerMobile = styled.section`
  ${() => css`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;

    margin-top: 64px;

    background-image: url(${PinkWaveBackground});
    background-repeat: no-repeat;
    background-position-y: 90vh;
    background-size: 100%;

    .image-content {
      position: relative;
      width: 397px;
      height: 438px;

      background-image: url(${lastPhaseImageMobile});
      background-position: center;
      background-size: contain;
    }
  `}
`

export const WhiteBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 90%;
    height: 428px;
    padding: 3.5rem 2rem;
    position: absolute;
    background-color: ${theme.colors.white};

    left: 0;
    right: 0;
    bottom: -85%;
    margin: -10rem auto 0 auto;

    border-radius: 0.8rem;
    box-shadow: 0px 6px 43px #00000029;
    text-align: center;

    h1 {
      max-width: 90%;
      margin-bottom: 32px;
    }

    ${media.greaterThan('medium')`
      width: 50rem;
      height: 40rem;
      min-height: 432px;
      padding-left: 3.5rem;

      align-items: flex-start;
      justify-content: center;

      text-align: left;
      left: unset;
      right: -10%;
      bottom: -5%;
      margin: 0;
    `}
  `}
`
