import * as S from './style'

export type LastEditionProps = {
  image: string
  playerSize?: 'large' | 'small'
  callBack: () => void
}

export default function LastEditionBottom({
  image,
  playerSize,
  callBack
}: LastEditionProps) {
  return (
    <>
      <S.Wrapper playerSize={playerSize}>
        <div className="videos_page">
          <section>
            <svg
              id="play"
              onClick={callBack}
              width="56"
              height="56"
              viewBox="0 0 56 56"
            >
              <path
                d="M28 0C22.4621 0 17.0486 1.64217 12.444 4.71885C7.83947 7.79553 4.25064 12.1685 2.13139 17.2849C0.012132 22.4012 -0.542361 28.0311 0.538025 33.4625C1.61841 38.894 4.28515 43.8831 8.20102 47.799C12.1169 51.7149 17.106 54.3816 22.5375 55.462C27.9689 56.5424 33.5988 55.9879 38.7151 53.8686C43.8315 51.7494 48.2045 48.1605 51.2812 43.556C54.3578 38.9514 56 33.5379 56 28C56 20.5739 53.05 13.452 47.799 8.20101C42.548 2.94999 35.4261 0 28 0ZM21.5385 40.9231V15.0769L38.7692 28L21.5385 40.9231Z"
                fill="#ffffff"
              />
            </svg>
            <img src={image} />
          </section>
        </div>
      </S.Wrapper>
    </>
  )
}
