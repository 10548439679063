import * as S from './style'
import Slider, { SliderSettings } from 'components/Slider'
import NextStepCardsTop from 'components/NextStepCardsTop'

import step1 from 'assets/img/next-step/step1.webp'
import step2 from 'assets/img/next-step/step2.webp'
import step3 from 'assets/img/next-step/step3.webp'

const settings: SliderSettings = {
  slidesToShow: 1,
  infinite: false,
  lazyLoad: 'progressive',
  dots: true,
  centerMode: true,
  centerPadding: '150px',
  arrows: false,
  touchThreshold: 100,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        centerPadding: '100px',
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        centerPadding: '60px',
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
}

export default function InfluencersSlider() {
  return (
    <S.Wrapper>
      <Slider settings={settings}>
        <NextStepCardsTop
          image={step1}
          text="Inscrição inicial pelo formulário de apresentação"
        />
        <NextStepCardsTop
          image={step2}
          text="Ter no mínimo de 10k seguidores no seu canal de Instagram, tiktok e/ou Youtube."
        />
        <NextStepCardsTop
          image={step3}
          text="Passar pela avaliação qualitativa do nosso time, que vai analisar o engajamento dos seus seguidores e o fit dos seus conteúdos."
        />
      </Slider>
    </S.Wrapper>
  )
}
