export default [
  {
    question: <p>O que é o Glamstar?</p>,
    answer: (
      <p>
        O Glamstar é o concurso que revelará a mais nova influenciadora do
        Brasil! Em sua terceira edição, essa competição vem com novos
        challenges, madrinhas e um super evento na grande final! A ganhadora
        leva o prêmio de R$ 100 MIL em contrato com a Glambox, enquanto a
        segunda, terceira e quarta colocadas ganham R$ 50 MIL, R$ 20 MIL e R$ 12
        MIL em contrato, respectivamente. Fique ligada nas nossas redes sociais
        para acompanhar todas as novidades da competição!
      </p>
    )
  },
  {
    question: <p>Como posso participar? </p>,
    answer: (
      <p>
        Todas as Glampartners podem participar de todos os challenges entre 1 e
        8, basta fazer se inscrever diretamente no portal
        https://partner.glambox.com.br/glampartner. Se o seu sonho é se tornar
        uma influencer, você ainda pode se inscrever para ser uma Glampartner em
        www.glampartners.com.br e participar das próximas edições do concurso.
        Você pode acompanhar o concurso pelas nossas redes sociais.
      </p>
    )
  },
  {
    question: <p>Quais são os challenges e como eles vão funcionar?</p>,
    answer: (
      <p>
        Os challenges serão surpresa e vão ser divulgados pelas nossas madrinhas
        ao longo dos meses. O concurso é dividido em 3 etapas, na primeira etapa
        (challenge 1 ao challenge 8) serão selecionadas 10 vencedoras que
        passarão para a etapa seguinte. Na segunda etapa (challenge 9 ao
        challege 11) as 80 selecionadas competirão entre si e ao final desta
        etapa serão escolhidas as 4 finalistas. Em cada etapa, as participantes
        devem postar seus vídeos e nos enviar pelo site. Fique de olho no nosso
        site e nas redes sociais da Glambox para não perder nada!
      </p>
    )
  },
  {
    question: <p>Sou uma participante, como saber se fui aprovada?</p>,
    answer: (
      <p>
        As participantes aprovadas serão comunicadas pelo email da inscrição, e
        também podem verificar seu status no portal glampartners.com.br.
      </p>
    )
  },
  {
    question: <p>Eu já sou Glampartner, posso participar?</p>,
    answer: (
      <p>
        Claro que pode! Acompanhe todos os challenges no portal
        glampartners.com.br. A sua vaga já está garantida na competição. Agora é
        só ficar ligada nas redes sociais para arrasar nos challenges e torcer
        para ser uma das finalistas. 😱
      </p>
    )
  },
  {
    question: <p>O que é Glampartner?</p>,
    answer: (
      <p>
        É o programa exclusivo de influenciadoras da Glambox. As Glampartners
        representam nossas marcas para seus seguidores e em troca recebem um
        plano de desenvolvimento, pagamentos em dinheiro, prêmios e benefícios
        exclusivos, incluindo a participação no concurso Glamstar. Clique aqui
        para saber mais:{' '}
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href="https://www.glampartners.com.br/"
        >
          www.glampartners.com.br/
        </a>
      </p>
    )
  },
  {
    question: <p>Quais são os challenges e como vão funcionar?</p>,
    answer: (
      <p>
        Os challenges serão surpresa! As nossas madrinhas vão divulgar ao longo
        dos meses, mas fique atenta e se prepare, pois serão incríveis! 😱✨
      </p>
    )
  },
  {
    question: <p>São quantas ganhadoras?</p>,
    answer: (
      <p>
        Serão 3 ganhadoras, nosso pódio vai ter o 1º, 2º e 3º lugar. Já estamos
        ansiosas para conhecer as novas influencers, quem sabe você não é uma
        delas?! 😍
      </p>
    )
  },
  {
    question: <p>Quais são os prêmios?</p>,
    answer: (
      <p>
        As 3 finalistas terão um contrato de Influenciadora com a Glambox. Sendo
        o contrato da 1ª colocada no valor de R$100 MIL, da 2ª no valor de R$50
        MIL e da 3ª no valor de R$20 MIL. Demais né?! 💕✨
      </p>
    )
  },
  {
    question: <p>Quem são as madrinhas?</p>,
    answer: (
      <p>
        A nossa primeira madrinha revelada é a{' '}
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href="https://instagram.com/tata"
        >
          Tata Estaniecki
        </a>
        , uma das maiores influenciadoras de beleza e lifestyle do Brasil. A
        segunda é{' '}
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/patriciaramos"
        >
          Patrícia Ramos
        </a>
        , nossa rainha do TikTok, cheia de bom humor e estilo. E a terceira
        madrinha que vai compor o time de GlamQueens é{' '}
        <a
          className="link"
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/camilaqueiroz"
        >
          Camila Queiroz
        </a>
        , atriz premiada e querida pelo público, com mais de 17 milhões de
        seguidores no Instagram. Já deu para perceber que vai ser incrível, né?!
        Então continue acompanhando as redes sociais da Glambox e das madrinhas
        para não perder nada! 👀 🧚
      </p>
    )
  },
  {
    question: <p>O que as madrinhas fazem?</p>,
    answer: (
      <p>
        Além de guiar nossas participantes, as madrinhas vão lançar todos os
        challenges e, claro, ajudar na decisão final para revelar a mais nova
        influenciadora do BRASIL. 💕😍
      </p>
    )
  },
  {
    question: <p>Vai ter evento? Onde será realizado?</p>,
    answer: (
      <p>
        Siiiim! Será um evento incrível na cidade de São Paulo, seguindo todas
        as medidas de prevenção contra a COVID-19. Os detalhes serão divulgados
        em breve, mas pode ir se preparando porque o evento de beleza mais
        esperado terá venda de ingressos disponível em nossa Loja! ✨
      </p>
    )
  },
  {
    question: <p>Só influencer pode ir no evento?</p>,
    answer: (
      <p>
        Não, também queremos você lá! Além de disponibilizarmos ingressos na
        nossa Loja online, vamos premiar as Glamgirls que participarem dos
        challenges com Glampoints e as mais engajadas também serão convidadas
        para o evento. Então já pode ir treinando, hein? 🎉
      </p>
    )
  },
  {
    question: (
      <p>
        Não moro em SP, se eu for finalista, a Glambox paga minha passagem e
        hospedagem?
      </p>
    ),
    answer: (
      <p>
        A Glambox irá arcar com todos os custos de estadia e transporte das 4
        grandes finalistas!
      </p>
    )
  }
]
