type LinkFooterProps = {
  title?: string
  url: string
}

export const LinksUniversoGlam: LinkFooterProps[] = [
  {
    title: 'Glambox',
    url: 'https://glambox.com.br/clube'
  },
  {
    title: 'Glampoints',
    url: 'https://glambox.com.br/clube/glampoints'
  },
  {
    title: 'Loja',
    url: 'https://glambox.com.br/loja'
  },
  {
    title: 'Glam beauty',
    url: 'https://glambox.com.br/loja/collections/marca-glam-beauty'
  },
  {
    title: 'Glam Mag',
    url: 'https://glambox.com.br/clube/conteudo/mag'
  },
  {
    title: 'Ella',
    url: 'https://ella.com.br/'
  }
]

export const LinksInstitucional: LinkFooterProps[] = [
  {
    title: 'Sobre nós',
    url: 'https://glambox.com.br/sobre-nos'
  },
  {
    title: 'Imprensa',
    url: 'https://b4a.com.br/#news'
  },
  {
    title: 'Política de Privacidade',
    url: 'https://glambox.com.br/politica-de-privacidade'
  },
  {
    title: 'Termos e condições de uso',
    url: 'https://glambox.com.br/termos-e-condicoes-de-uso'
  }
]

export const LinksPodemosTeAjudar: LinkFooterProps[] = [
  {
    title: 'Entre em contato',
    url: 'https://glambox.com.br/sac'
  },
  {
    title: 'Parcerias',
    url: 'https://b4a.com.br/#contact'
  }
]

export const LinksMens: LinkFooterProps[] = [
  {
    title: `Men's`,
    url: 'https://mensmarket.com.br/collections/marca-mens'
  },
  {
    title: `Clube da Men's`,
    url: 'https://mensmarket.com.br/pages/clube'
  },
  {
    title: `Men's Market`,
    url: 'https://mensmarket.com.br/'
  },
  {
    title: 'Malmo',
    url: 'https://mensmarket.com.br/collections/marca-malmo'
  }
]

export const LinksB4a: LinkFooterProps[] = [
  {
    title: 'A empresa',
    url: 'https://b4a.com.br/'
  },
  {
    title: 'Para marcas',
    url: 'https://b4a.com.br/#brands'
  },
  {
    title: 'B4A Connect',
    url: 'https://b4a.com.br/#b4aconnect'
  },
  {
    title: 'Influenciadores',
    url: 'https://b4a.com.br/#influencers'
  }
]
