import styled from 'styled-components'
import { motion } from 'framer-motion'
import media from 'styled-media-query'

export const Wrapper = styled.main`
  border-radius: 25px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 384px;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const ChallengeWinnersCard = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, 187px);
  row-gap: 20px;
  column-gap: 0px;

  width: 100%;
  padding: 5rem 0rem 2.4rem;
  background-color: #fff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #9f43f0;

  position: relative;
  margin-top: -30px;

  a {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.darkGray};

    transition: 0.3s ease-in-out;

    p {
      font-size: 1.4rem;
    }

    img {
      object-fit: scale-down;
      max-width: 188px;
      height: 120px;
      margin: 0px auto 5px auto;
    }

    &:hover {
      filter: opacity(0.9);
    }
  }

  ${media.greaterThan('medium')`
    a {
      p {
        font-size: 1.4rem;
      }
      img {
        max-width: 160px;
        height: 98px;
        margin-bottom: 0.5rem;
      }
    }
`}
`

export const GodmotherChallenge = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;

  z-index: 1;
  overflow: hidden;

  width: 384px;
  border-radius: 9px 9px 0px 0px;

  img {
    width: 100%;
    height: 254px;

    object-fit: cover;
    filter: brightness(0.58);
  }

  .description {
    height: 70px;
    overflow: hidden;
    position: absolute;
    margin-top: 175px;
    left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: end;
  }

    p {
      text-align: left;
      color: #fff;
      font-size: 1.6rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`

export const GodMotherChallengeBottom = styled(motion.div)`
  .bottom-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9f43f0;
    width: 384px;
    padding: 10px 20px;
    border-radius: 0px 0px 9px 9px;

    transition: 0.3s ease;

    cursor: pointer;

    &:hover {
      filter: brightness(0.9);
    }

    span {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
      color: #fff;
    }

    svg {
      margin-left: 5px;
      align-self: flex-end;
    }
  }
`
