import * as S from './style'
import { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import ReCAPTCHA from 'react-google-recaptcha'

import { useBreakpoint } from 'hooks/useBreakpoint'
import { useForm } from 'react-hook-form'

import logo from 'assets/img/subscription/logo.webp'

import SubscriptionShape from 'components/SubscriptionShape'
import Button from 'components/Button'
import { useHistory } from 'react-router-dom'

type FormProps = {
  name: string
  surname: string
  email: string
  phone: string
  instagram: number
  tiktok: number
  youtube: number
}

export default function Subscription() {
  const { isMobile } = useBreakpoint(768)

  const [isDataSent, setIsDataSent] = useState(false)
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [formMessage, setFormMessage] = useState('')
  const [formBody, setFormBody] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    instagram: 0,
    tiktok: 0,
    youtube: 0
  })

  const history = useHistory()

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
    trigger
  } = useForm()

  const onSubmit = (body: FormProps) => {
    setFormBody(body)
    setShowCaptcha(true)
  }

  const handleReCaptchaVerify = async () => {
    setTimeout(() => {
      setShowCaptcha(false)
    }, 1000)

    const hostName = window.location.host

    let baseUrl = ''

    if (hostName == 'www.glamstar.com.br' || hostName == 'glamstar.com.br') {
      baseUrl = 'https://apiclient.glambox.com.br/open/glamstar/register'
    } else {
      baseUrl = 'https://apiclient-dev.glambox.com.br/open/glamstar/register'
    }

    await fetch(baseUrl, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formBody)
    })
      .then((response) => response.json())
      .then((data) => {
        setFormMessage(data.result.message)
        setIsDataSent(true)

        localStorage.setItem('id', data.result.glamstar.glamstarRegisterId)

        if (data.result.message == 'Cadastrado com sucesso!') {
          setFormMessage(data.result.message + ' Você será redirecionada...')
          if (data.result.glamstar.status) {
            setTimeout(() => {
              history.push('/parabens')
            }, 4000)
          } else {
            setTimeout(() => {
              history.push('/proxima-fase')
            }, 4000)
          }
        }

        setTimeout(() => {
          setIsDataSent(false)
        }, 5000)
      })
      .catch((error) => console.log(error))

    reset()
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <S.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0 }}
    >
      <S.Banner>
        <img src={logo} alt="seja a próxima glamstar" />
      </S.Banner>

      <S.FormContainer>
        <SubscriptionShape mobile={isMobile} />
        <S.Form method="post" onSubmit={handleSubmit(onSubmit)}>
          <h1>Inscreva-se na primeira etapa</h1>
          <div className="display-flex">
            <S.InputContainer>
              <S.TextField
                type="text"
                error={errors.firstName}
                placeholder="Nome"
                {...register('firstName', { required: 'Escreva o seu nome' })}
              />
              {errors.firstName && (
                <S.ErrorMessage>{errors.firstName.message}</S.ErrorMessage>
              )}
            </S.InputContainer>

            <S.InputContainer>
              <S.TextField
                type="text"
                error={errors.lastName}
                placeholder="Sobrenome"
                {...register('lastName', {
                  required: 'Escreva o seu sobrenome'
                })}
              />
              {errors.lastName && (
                <S.ErrorMessage>{errors.lastName.message}</S.ErrorMessage>
              )}
            </S.InputContainer>
          </div>
          <div className="display-flex">
            <S.InputContainer>
              <S.TextField
                type="email"
                error={errors.email}
                placeholder="E-mail"
                {...register('email', {
                  required: 'Escreva o seu e-mail',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Digite um e-mail válido'
                  }
                })}
              />
              {errors.email && (
                <S.ErrorMessage>{errors.email.message}</S.ErrorMessage>
              )}
            </S.InputContainer>

            <S.InputContainer>
              <S.TextField
                type="tel"
                error={errors.phone}
                placeholder="Celular"
                {...register('phone', {
                  required: 'Escreva o número do seu celular',
                  pattern: {
                    value:
                      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                    message: 'Insira DDD + número'
                  }
                })}
                onKeyUp={() => {
                  trigger('phone')
                }}
              />
              {errors.phone && (
                <S.ErrorMessage>{errors.phone.message}</S.ErrorMessage>
              )}
            </S.InputContainer>
          </div>
          <div className="display-flex">
            <S.InputContainer>
              <S.TextField
                type="number"
                error={errors.instagram}
                placeholder="Seguidores no Instagram"
                {...register('instagram', {
                  required: 'Nos diga quantos seguidores você tem'
                })}
              />
              {errors.instagram && (
                <S.ErrorMessage>{errors.instagram.message}</S.ErrorMessage>
              )}
            </S.InputContainer>

            <S.InputContainer>
              <S.TextField
                type="number"
                error={errors.tiktok}
                placeholder="Seguidores no TikTok"
                {...register('tiktok', {
                  required: 'Nos diga quantos seguidores você tem'
                })}
              />
              {errors.tiktok && (
                <S.ErrorMessage>{errors.tiktok.message}</S.ErrorMessage>
              )}
            </S.InputContainer>

            <S.InputContainer>
              <S.TextField
                type="number"
                error={errors.youtube}
                placeholder="Seguidores no Youtube"
                {...register('youtube', {
                  required: 'Nos diga quantos seguidores você tem'
                })}
              />
              {errors.youtube && (
                <S.ErrorMessage>{errors.youtube.message}</S.ErrorMessage>
              )}
            </S.InputContainer>
          </div>
          <AnimatePresence>
            {isDataSent && (
              <S.SentMessage
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: '5rem' }}
                exit={{ opacity: 0, height: 0 }}
              >
                {formMessage}
              </S.SentMessage>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {showCaptcha && (
              <S.RecaptchaContainer
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'fit-content' }}
                exit={{ opacity: 0, height: 0 }}
              >
                <ReCAPTCHA
                  sitekey="6LfgueUcAAAAANUPHbOahYbOYFHtZxisiL3bKfb3"
                  onChange={handleReCaptchaVerify}
                />
              </S.RecaptchaContainer>
            )}
          </AnimatePresence>
          <Button color="red" type="submit">
            ENVIAR
          </Button>
          <S.FormText>
            Nesse momento não se preocupe com o seu número de seguidores, só
            queremos te conhecer melhor ;)
          </S.FormText>
        </S.Form>
      </S.FormContainer>
    </S.Wrapper>
  )
}
