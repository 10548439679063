import * as S from './style'

type CardsProps = {
  image: string
  text: string
}

export default function NextStepCardsTop({ image, text }: CardsProps) {
  return (
    <S.Wrapper>
      <img src={image} alt={image} />
      <p>{text}</p>
    </S.Wrapper>
  )
}
