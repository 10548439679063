import { ButtonHTMLAttributes } from 'react'
import { useHistory } from 'react-router-dom'

import * as S from './style'

export type ButtonProps = {
  children: React.ReactNode
  link?: string
  outline?: boolean
  round?: boolean
  isActive?: boolean
  color?: 'purple' | 'red' | 'darkPink'
} & ButtonHTMLAttributes<HTMLButtonElement>

export default function Button({
  children,
  link,
  color = 'purple',
  round = false,
  outline = false,
  isActive = false,
  onClick
}: ButtonProps) {
  const history = useHistory()

  return (
    <S.Wrapper
      onClick={link ? () => history.push(link) : onClick}
      color={color}
      outline={outline}
      round={round}
      isActive={isActive}
    >
      {children}
    </S.Wrapper>
  )
}
