export default function InfluencerSquare() {
  return (
    <svg viewBox="0 0 249 248">
      <defs>
        <linearGradient
          id="a"
          x1=".5"
          y1="-.373"
          x2=".5"
          y2=".777"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#939393" stopOpacity="0" />
          <stop offset=".54" stopColor="#fa98be" stopOpacity="0" />
          <stop offset="1" stopColor="#a044f1" />
        </linearGradient>
      </defs>
      <rect width="249" height="248" rx="16" fill="url(#a)" />
      <text
        transform="translate(78 173)"
        stroke="#707070"
        fontSize="166"
        fontFamily="Montserrat-Bold,Montserrat"
        fontWeight="700"
        opacity=".4"
      >
        <tspan x="0" y="0">
          ?
        </tspan>
      </text>
    </svg>
  )
}
