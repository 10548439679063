import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.main`
  text-align: center;
  min-width: 30rem;

  svg {
    margin: 0 auto;
    width: 100%;
  }
`
export const Image = styled.img`
  border-radius: 9px;
  width: 284px;
  height: 270px;

  ${media.greaterThan('medium')`
    width: 312px !important;
    height: 296px;
  `}
`
export const Title = styled.h3`
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 2.2rem;
  font-weight: 500;
`
export const Text = styled.p`
  font-size: 1.4rem;
  font-weight: 300;

  ${media.greaterThan('medium')`
  font-size: 1.6rem;
    width: 312px !important;
    height: 296px;
  `}
`
