import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import media from 'styled-media-query'

export const Wrapper = styled(motion.main)`
  margin: 0.5rem auto;
  box-shadow: 20px 23px 26px #0000000f;
  border-radius: 0.9rem;
  width: 100%;
  max-width: 80rem;
`

export const Question = styled(motion.div)`
  ${({ theme }) => css`
    padding: 1rem 2rem;
    min-height: 5rem;
    width: 100%;
    max-width: 80rem;

    border-radius: 0.9rem;
    background-color: ${theme.colors.purple};

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    svg {
      width: 2rem;
    }

    p {
      color: ${theme.colors.white};
      font-weight: ${theme.font.semiBold};
      font-size: 1.4rem;
      max-width: 85%;
      font-family: 'Open Sans', sans-serif;
    }

    ${media.greaterThan('medium')`
      min-height: 7rem;
      p{
        font-size: 1.6rem;
      }
    `}
  `}
`

export const Answer = styled(motion.div)`
  background-color: #9f43f0;
  border-radius: 0 0 0.9rem 0.9rem;
  p {
    color: #fff;
    padding: 1rem 3.4rem 2rem 1rem;
    font-weight: 300;
    font-size: 1.4rem;
    font-family: 'Open Sans', sans-serif;
  }

  ${media.greaterThan('medium')`
      p{
        padding: 0rem 4.9rem 2rem 2.5rem;
        font-size: 1.6rem;
      }

    `}
`
