import * as S from './style'

import MidShape from 'components/MidShape'

import logoGlam from 'assets/img/logo-glamstar.webp'
import Phase1Img from 'assets/img/next-step/step1.webp'
import Phase2Img from 'assets/img/approved/step2.webp'
import Phase3Img from 'assets/img/next-step/step3.webp'

import logoParabens from 'assets/img/logo-parabens.webp'

import { useBreakpoint } from 'hooks/useBreakpoint'
import Button from 'components/Button'
import Slider, { SliderSettings } from 'components/Slider'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export default function Compliment() {
  const { isMobile } = useBreakpoint(768)

  const settings: SliderSettings = {
    slidesToShow: 1,
    infinite: false,
    lazyLoad: 'progressive',
    dots: true,
    centerMode: true,
    centerPadding: '200px',
    arrows: false,
    touchThreshold: 100,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '150px',
          arrows: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: '100px',
          arrows: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          centerPadding: '50px',
          arrows: false,
          slidesToShow: 1
        }
      }
    ]
  }

  const history = useHistory()

  useEffect(() => {
    getPartnerFollowersNumber()
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getPartnerFollowersNumber = async () => {
    const savedId = localStorage.getItem('id')
    const hostName = window.location.host
    let baseUrl =
      'https://apiclient-dev.glambox.com.br/open/glamstar/' + savedId

    if (hostName == 'glamstar.com.br') {
      baseUrl = 'https://apiclient.glambox.com.br/open/glamstar/' + savedId
    }

    await fetch(baseUrl, {
      method: 'get'
    })
      .then((response) => response.json())
      .then((data) => {
        if (savedId) {
          if (data.result.status == 0) {
            history.push('/proxima-fase')
          }
        } else {
          history.push('/inscricoes')
        }
      })
      .catch((error) => console.log(error))
  }

  return (
    <S.Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0 }}
    >
      <S.MidShapeContainer>
        <>
          {!isMobile && (
            <img
              className="logo-glam"
              src={logoGlam}
              alt="seja a próxima glamstar"
            />
          )}

          <div>
            <img className="logo-happy" src={logoParabens} alt="Parabens" />
            <h1>Confira abaixo as próximas etapas</h1>
          </div>
        </>
        <MidShape mobile={isMobile} />
      </S.MidShapeContainer>

      {isMobile ? (
        <S.PhasesContainerMobile>
          <Slider settings={settings}>
            <S.Phase>
              <img src={Phase1Img} alt="Etapa 1" />
              <p>Inscrição inicial pelo formulário de apresentação</p>
            </S.Phase>

            {/* <S.DividerPhase /> */}
            <S.Phase>
              <img src={Phase2Img} alt="Etapa 2" />
              <p>
                Ter no mínimo de 10k seguidores no seu canal de Instagram,
                tiktok e/ou Youtube.
              </p>
            </S.Phase>

            {/* <S.DividerPhase /> */}
            <S.Phase>
              <img src={Phase3Img} alt="Etapa 3" />
              <p>
                Passar pela avaliação qualitativa do nosso time, que vai
                analisar o engajamento dos seus seguidores e o fit dos seus
                conteúdos.
              </p>
            </S.Phase>
          </Slider>
        </S.PhasesContainerMobile>
      ) : (
        <S.PhasesContainer>
          <div>
            <S.Phase>
              <img src={Phase1Img} alt="Etapa 1" />
              <p>Inscrição inicial pelo formulário de apresentação</p>
            </S.Phase>

            <S.DividerPhase />
            <S.Phase>
              <img src={Phase2Img} alt="Etapa 2" />
              <p>
                Ter no mínimo de 10k seguidores no seu canal de Instagram,
                tiktok e/ou Youtube.
              </p>
            </S.Phase>

            <S.DividerPhase />
            <S.Phase>
              <img src={Phase3Img} alt="Etapa 3" />
              <p>
                Passar pela avaliação qualitativa do nosso time, que vai
                analisar o engajamento dos seus seguidores e o fit dos seus
                conteúdos.
              </p>
            </S.Phase>
          </div>

          <div className="description">
            <p>
              Uhul! Você concluiu as duas primeiras etapas do processo de
              inscrição. Para ser aprovada na última etapa, precisamos te
              conhecer melhor e avaliar o seu conteúdo e o engajamento das suas
              seguidoras.
            </p>
            <p>
              Ao ser aprovada na última etapa, além de se qualificar para
              participar no Glamstar 2022, você também automaticamente vira uma{' '}
              <a
                href="https://glampartners.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                Glampartner
              </a>{' '}
              - influencer parceira da Glambox.
            </p>
          </div>
        </S.PhasesContainer>
      )}

      {isMobile ? (
        <S.LastPhaseContainerMobile>
          <div className="image-content">
            <S.WhiteBox>
              <h1>Vamos te conhecer melhor nessa última etapa!</h1>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfrSMb7i31uNwlf7vWjNI1-vs-K677LMC2Xuey9s8IO02X5tQ/viewform">
                <Button color="red">Quero me inscrever</Button>
              </a>
            </S.WhiteBox>
          </div>
        </S.LastPhaseContainerMobile>
      ) : (
        <S.LastPhaseContainer>
          <div className="image-content">
            <S.WhiteBox>
              <h1>Vamos te conhecer melhor nessa última etapa!</h1>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfrSMb7i31uNwlf7vWjNI1-vs-K677LMC2Xuey9s8IO02X5tQ/viewform">
                <Button color="red">Quero me inscrever</Button>
              </a>
            </S.WhiteBox>
          </div>
        </S.LastPhaseContainer>
      )}
    </S.Wrapper>
  )
}
