import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    .slick-track,
    .slick-list {
      display: flex;
      margin-left: -${theme.spacings.small};
    }
    .slick-slide > div {
      margin: 0 ${theme.spacings.xxsmall};
      flex: 1 0 auto;
      height: 100%;
    }
    .slick-list {
      margin: 0 -${theme.spacings.xxsmall};
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      margin-top: -1rem;
      min-height: 4.5rem;
      padding-top: 2rem;

      li {
        margin: 0 0.7rem;
        list-style: none;

        button {
          cursor: pointer;
          height: 2rem;
          width: 2rem;
          border-radius: 5rem;
          background-color: #e1e1e1;
          border: none;
          font-size: 0;
          transition: 0.3s;
        }

        &.slick-active {
          button {
            height: 4rem;
            width: 1.5rem;
            background-color: ${theme.colors.darkPink};
            transition: 0.3s;
          }
        }

        &::marker {
          content: none;
        }
      }
    }

    ${media.greaterThan('medium')`
      .slick-list {
        justify-content: center;
        margin: auto;
        max-width: 1200px;
      }
      .slick-slide {
        width: 100%;
      }
    `}
  `}
`
