import InfluencerSquare from 'components/InfluencerSquare'
import * as S from './style'

type InfluencersProps = {
  image?: string
  title?: string
  text?: string
  isClickable?: boolean
  onClick?: () => void
}

export default function InfluencersCard({
  image,
  title,
  text,
  isClickable = false,
  onClick
}: InfluencersProps) {
  return (
    <S.Wrapper
      style={{ cursor: isClickable ? 'pointer' : 'default' }}
      onClick={onClick}
    >
      {image ? <S.Image src={image} alt={title} /> : <InfluencerSquare />}
      <S.Title>{title}</S.Title>
      <S.Text>{text}</S.Text>
    </S.Wrapper>
  )
}
