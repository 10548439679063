import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { darken } from 'polished'
import { ButtonProps } from '.'

export const Wrapper = styled.button<
  Pick<ButtonProps, 'color' | 'outline' | 'round' | 'isActive'>
>`
  ${({ theme, color, outline, round, isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 1.5rem 3rem;
    max-width: 50rem;
    width: 100%;
    height: 4rem;

    background-color: ${outline ? 'transparent' : theme.colors[color!]};
    color: ${outline ? theme.colors[color!] : theme.colors.white};

    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.small};

    border-style: ${outline ? 'solid' : 'none'};
    border-color: ${outline && theme.colors[color!]};
    border-width: ${outline && '2px'};
    border-radius: ${round ? '3.2rem' : '0.8rem'};
    cursor: pointer;

    transition: 0.3s;

    ${media.greaterThan('medium')`
        width: 24.2rem;
        height: 5rem;
        border-radius: ${round ? '3.2rem' : '0.8rem'};
    `}

    ${!isActive &&
    ` &:hover {
      transition: 0.3s;
      background-color: ${darken(0.1, theme.colors[color!])};
      color: ${theme.colors.white};
    }`}
  `}

  ${({ theme, isActive, color, outline }) =>
    isActive &&
    css`
      background-color: ${outline ? theme.colors[color!] : 'transparent'};
      color: ${outline ? theme.colors.white : 'transparent'};
    `}
`
