import styled, { css } from 'styled-components'
import media from 'styled-media-query'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    .slick-track,
    .slick-list {
      display: flex;
    }

    .slick-list {
      margin: 0 -${theme.spacings.xxsmall};
    }

    .slick-arrow {
      height: 15px;
      z-index: 1;
      transition: 0.3s;
      cursor: pointer;
      opacity: 0.7;

      &.slick-prev {
        background-image: url('assets/img/assets/img/arrow-down-purple.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        height: 40px;
        position: absolute;
        left: 75px;
        bottom: 115px;
        transform: rotate(90deg);
        &:hover {
          transform: scale(1.15) rotate(90deg);
        }
        ${media.greaterThan('medium')`
          left: 115px;
        `}
      }
      &.slick-next {
        background-image: url('assets/img/assets/img/arrow-up-purple.svg');
        background-repeat: no-repeat;
        background-position: center;
        width: 40px;
        height: 40px;
        position: absolute;
        right: 75px;
        bottom: 115px;
        transform: rotate(-90deg);
        &:hover {
          transform: scale(1.15) rotate(-90deg);
        }
        ${media.greaterThan('medium')`
          right: 115px;
        `}
      }
    }

    .slick-dots {
      display: flex !important;
      justify-content: center;
      margin-top: 0rem;

      li {
        margin: 0 0.7rem;
        list-style: none;

        button {
          cursor: pointer;
          height: 1rem;
          width: 1rem;
          border-radius: 5rem;
          background-color: #e1e1e1;
          border: none;
          font-size: 0;
          transition: 0.3s;
        }

        &.slick-active {
          button {
            height: 1rem;
            width: 1rem;
            background-color: ${theme.colors.darkPink};
            transition: 0.3s;
          }
        }

        &::marker {
          content: none;
        }
      }
    }

    ${media.greaterThan('medium')`
      .slick-list {
        justify-content: flex-start;
      }
      .slick-slide > div {
        width: 200px;      }
    `}
  `}
`
export const List = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 24px;
  order: 1;
  margin-top: 10px;

  .video-old {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 124px;
      height: 124px;
      border-radius: 8px;
      filter: brightness(0.5);
    }

    .description {
      margin-top: 6px;
      text-align: center;
      order: 2;
      line-height: 19px;
      span {
        font-family: 'Open Sans', sans-serif;
        text-align: left;
        font-size: 1.6rem;
        font-weight: 300;
        width: 175px;
        display: inline-flex;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: ltr;
      }
      p {
        font-family: 'Open Sans', sans-serif;
        text-align: left;
        font-size: 1.4rem;
        font-weight: 300;
        width: 175px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: ltr;
      }
    }
  }

  ${media.greaterThan('medium')`
     margin-bottom: 0;
      .video-old {
        img {
          width: 124px;
          height: 124px;
          border-radius: 16px;
          filter: brightness(0.5);
        }
        .description {
          margin-top: 0px;
          width: 200px;
          height: 50px;
          line-height: 19px;
          span{
            font-size: 1.8rem;
          } 
          p {
            font-size: 1.4rem;

          }
        }
      }
  `}
`
