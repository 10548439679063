import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { CardsProps } from '.'

export const Wrapper = styled.main<Pick<CardsProps, 'isSlideItem'>>`
  ${({ theme, isSlideItem }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: ${theme.colors.white};

    position: relative;
    z-index: 10;

    img {
      width: 60%;
    }

    h3 {
      margin: 2.5rem 0;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: ${theme.font.sizes.small};
    }

    iframe {
      margin-top: 2rem;
    }

    ${isSlideItem &&
    css`
      padding: 2rem;
      box-shadow: 0px 13px 33px #00000029;
      border-radius: 1.6rem;
    `}

    ${media.greaterThan('medium')`
      padding: 3rem 0;
      width: 20%;
    `}
  `}
`
