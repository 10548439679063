import * as S from './style'
import InfluencersCard from 'components/InfluencersCard'
import Slider, { SliderSettings } from 'components/Slider'

import influencer1 from 'assets/img/influencer-1.webp'
import influencer2 from 'assets/img/influencer-2.webp'
import influencer3 from 'assets/img/influencer-3.webp'

const settings: SliderSettings = {
  slidesToShow: 1,
  infinite: false,
  lazyLoad: 'progressive',
  dots: true,
  centerMode: true,
  centerPadding: '200px',
  arrows: false,
  touchThreshold: 100,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        centerPadding: '150px',
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: '100px',
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        centerPadding: '35px',
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
}

export default function InfluencersSlider() {
  return (
    <S.Wrapper>
      <Slider settings={settings}>
        <InfluencersCard
          image={influencer1}
          title="Tata Estaniecki"
          text="Uma das maiores influenciadoras de beauty e lifestyle do Brasil e mamãe da baby mais fofa, vem com todo seu carisma compor nosso time de apresentadoras do Glamstar 2022."
          onClick={() => window.open('https://instagram.com/tata')}
        />

        <InfluencersCard
          image={influencer3}
          title="Camila Queiroz"
          text="Atriz premiada e querida pelo público, Camila é puro sucesso e arrasa nas redes sociais com mais de 17 milhões de seguidores no Instagram e 3 milhões no TikTok. Ela vem para fechar com chave de ouro o time das madrinhas Glamstar!"
          onClick={() =>
            window.open('https://www.instagram.com/camilaqueiroz/')
          }
        />

        <InfluencersCard
          image={influencer2}
          title="Patrícia Ramos"
          text="Para com isso!!! Patrícia Ramos vem com muito humor compor o nosso time de apresentadoras do Glamstar 2022. A influenciadora conta com mais de 3 milhões de seguidores no Tiktok e 2,6 milhões no Instagram."
          onClick={() =>
            window.open('https://www.instagram.com/patriciaramos/')
          }
        />
      </Slider>
    </S.Wrapper>
  )
}
