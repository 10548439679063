import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import media from 'styled-media-query'

import bannerMobile from 'assets/img/next-step/banner-mb.webp'
import bannerDesk from 'assets/img/next-step/banner-desk.webp'

export const Wrapper = styled(motion.main)`
  overflow: hidden;
`

export const Banner = styled.section`
  padding-bottom: calc(1150 / 600 * 100%);

  background-image: url(${bannerMobile});
  background-position: center -2rem;
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;

  .logo-top {
    position: absolute;
    width: 100%;
    bottom: 30vw;
    right: 0;
  }

  ${media.greaterThan('medium')`
    padding-bottom: calc(700 / 1587 * 100%);
    background-position: center;

    background-image: url(${bannerDesk});

      .logo-top {
        position: absolute;
        width: 50%;

        top: 2vw;
        bottom: 0;
        left: 0;
        right: 47vw;

        margin: auto;
      }

      .logo-glam {
        position: absolute;
        width: 12%;
        bottom: 35vw;
        right: 0;
        left: 5vw;
      }
  `}
`

export const StepsContainer = styled.section`
  ${({ theme }) => css`
    position: relative;
    margin-top: -8vw;

    .cards-container {
      margin: 0 auto;
      max-width: 100rem;

      display: flex;
      align-items: center;
      justify-content: space-around;

      flex-wrap: wrap;
    }

    .text {
      margin: 3rem auto 0 auto;
      max-width: 110rem;
      font-size: ${theme.font.sizes.small};
      text-align: center;
      padding: 3rem;

      h1 {
        margin-bottom: 2rem;
      }
    }

    .dash {
      height: 0.8rem;
      width: 10vw;
      max-width: 10rem;
      margin-bottom: 3rem;
      border-radius: 2rem;
      background-color: ${theme.colors.lightGray};
    }

    .top-shape {
      margin-top: -18vw;
    }

    .bottom-shape {
      margin-bottom: -20vw;
    }

    ${media.greaterThan('medium')`
      margin-top: -9vw;

      .text {
        z-index: 10;
        position: relative;
      }

      .cards-container {
        position: relative;
        margin-top: -5vw;
        z-index: 10;
      }

      .bottom-shape {
        margin-bottom: -10vw;
        z-index: 1;
      }
    `}
  `}
`

export const WhatHappens = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.lightPink};

    .content {
      padding: 25vw 0 1rem 0;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    }

    div {
      padding: 0 4vw;
    }

    h1 {
      text-align: center;
      font-size: ${theme.font.sizes.medium};
    }

    p {
      text-align: center;
      margin: 2rem 0;
    }

    a {
      font-weight: ${theme.font.semiBold};
      color: ${theme.colors.red};
      text-decoration: underline;

      &:hover {
        opacity: 0.6;
      }
    }

    img {
      width: 80%;
      max-width: 51rem;
    }

    .bottom-shape {
      margin-bottom: -21vw;
    }

    ${media.greaterThan('medium')`
      .content {
        padding: 12vw 2vw 1rem 2vw;
        flex-direction: row;
        justify-content: space-around;
      }

      .bottom-shape {
        margin-bottom: -10vw;
      }

      div {
        padding: 0 0 0 5rem;
        max-width: 80rem;
      }

      img {
        max-width: 40rem;
      }

      h1 {
        text-align: left;
        font-size: ${theme.font.sizes.midlarge};
      }

      p {
        text-align: left;
      }
    `}
  `}
`

export const WhatToDo = styled.section`
  ${({ theme }) => css`
    position: relative;
    z-index: ${theme.layers.base};

    .text {
      h1,
      p {
        text-align: center;
        margin: 3rem 0;
      }
    }

    .pyramid {
      width: 100%;
      max-width: 61rem;
    }

    .bottom-shape {
      position: absolute;
      bottom: 130vw;
      z-index: -1;
    }

    ${media.greaterThan('medium')`
      .bottom-shape {
        bottom: 12.5vw;
      }
    `}
  `}
`
export const PyramidContainer = styled.section`
  padding: 20vw 4vw 1rem 4vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.greaterThan('medium')`
    padding: 12vw 4vw 1rem 4vw;

    flex-direction: row-reverse;
    justify-content: space-around;

    .text {
      max-width: 42rem;

      h1,
      p {
        text-align: left;
      }
    }
  `}
`
export const SliderContainer = styled.section`
  ${({ theme }) => css`
    .text {
      margin: 5rem auto;
      padding: 0 1rem;
      max-width: 85rem;
    }

    .cards-container {
      margin: 5rem auto;
      max-width: 140rem;

      display: flex;
      justify-content: space-around;

      background-color: ${theme.colors.white};

      box-shadow: 0px 13px 33px #00000029;

      border-radius: 1.6rem;
    }

    .bottom-title {
      margin-top: 8rem;
      text-align: center;
    }

    ${media.greaterThan('medium')`
      margin: 0 3rem;
    `}
  `}
`

export const SignUp = styled.section`
  ${({ theme }) => css`
    position: relative;
    margin-top: -150vw;
    padding-top: 160vw;
    background-color: ${theme.colors.lightPink};

    z-index: 1;

    .content {
      padding: 0 7vw;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .text-cta {
      height: 30rem;
      margin-top: 1rem;
      max-width: 43rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      button {
        width: 23rem;
      }
    }

    img {
      width: 100%;
      max-width: 51rem;
    }

    p {
      text-align: center;
    }

    .bottom-shape {
      margin-bottom: -21vw;
    }

    ${media.greaterThan('medium')`
      margin-top: -25vw;
      padding-top: 30vw;

      .content {
        padding: 0 3vw;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
      
      .text-cta {
        align-items: flex-start;
        height: 35rem;
      }

      p {
        text-align: left;
      }

      .bottom-shape {
        margin-bottom: -10vw;
      }
    `}
  `}
`

export const VideosContainer = styled.section`
  padding: 30vw 0 10rem 0;

  .text {
    padding: 0 5vw;
    max-width: 100rem;
    margin: 0 auto;
    text-align: center;
  }

  p {
    margin: 3rem 0;
  }

  .text {
    margin-top: 5rem;
  }

  h1 {
  }

  ${media.greaterThan('medium')`
    padding-top: 15vw;
  `}
`

export const Modal = styled.section`
  ${({ theme }) => css`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: ${theme.layers.modal};

    svg {
      cursor: pointer;
      width: 5rem;
      position: absolute;
      right: 2rem;
      top: 2rem;
      fill: ${theme.colors.white};
    }

    img {
      width: 100%;
      opacity: 0;
    }

    iframe {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .content {
      margin-top: 10vh;
      position: relative;
      width: 100%;
      max-width: 90rem !important;
    }

    ${media.greaterThan('medium')`
      svg {
        right: 5vw;
        top: 5vw;
      }
    `}
  `}
`
