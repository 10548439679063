import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import { motion } from 'framer-motion'

import topBannerMb from 'assets/img/banner-top-mb2.png'
import topBannerDesk from 'assets/img/banner-top-desk2.png'
import midBanner from 'assets/img/banner-mid.webp'

export const Wrapper = styled(motion.main)`
  overflow: hidden;

  .top-shape-pink {
    margin-bottom: -2vw;
  }

  .bottom-shape-pink {
    margin-top: -1vw;
  }

  .bottom-shape-light-pink {
    margin-bottom: -0.6rem;
  }

  ${media.greaterThan('huge')`
  .bottom-shape-pink {
    margin-top: -0.2vw;
  }
    `}
`

export const Banner = styled.section`
  ${({ theme }) => css`
    position: relative;
    padding-bottom: calc(900 / 600 * 46%);
    background-image: url(${topBannerMb});
    background-size: cover;
    background-position: center bottom;
    background-color: ${theme.colors.midWhite};
    width: 100%;

    ${media.greaterThan('medium')`
      display: flex;
      justify-content: center;

      padding-bottom: calc(859 / 1587 * 100%);
      background-image: url(${topBannerDesk});
      background-color: #401e37;

      .logo-glam {
        position: absolute;
        width: 12%;
        bottom: 45vw;
        right: 0;
        left: 12vw;
      }
    `}
  `}
`

export const BannerContent = styled.section`
  ${({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;

    bottom: 0;

    div {
      left: 0;
      right: 0;
      top: 42vw;
      margin: 0 auto;

      position: absolute;
      height: 15rem;

      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        color: ${theme.colors.white};
        font-size: 2.8rem;
        text-align: center;
      }

      h2 {
        color: ${theme.colors.white};
        font-size: 40px;
        font-weight: 500;
        text-align: center;
        font-size: 1.4rem;
      }

      button {
        width: 55%;
      }
    }

    ${media.greaterThan('medium')`
      max-width: 90%;
      bottom: -1vw;
      background-size: 78% 100%;

      div {
        bottom: 9.5vw;
        top: unset;
        height: 19rem;
        width: 100%;

        h1 {
          width: 100%;
          white-space: nowrap;
          font-size: 4.4rem;
        }

        h2 {
          font-size: 1.6rem;
        }

        button {
          min-width: 35%;
          max-width: 35%;
          min-height: 40%;
          max-height: 40%;
        }
      }
    `}
  `}
`

export const TopVideoContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.midWhite};

    position: relative;

    .title-content{
      max-width: 1200px;
      margin: 0 auto;
      font-size: 2.4rem;
      font-weight: 600;
      padding: 0 1rem;
      text-align: center;
      word-spacing: 0.3rem;
    }

    .introduction-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content:center;
      padding: 20px 20px 20px;
      max-width: 1187px;
      min-height: 35rem;
      margin: 0 auto -55px auto;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;
    }

    .main-video-content {
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;
      margin-top: 2rem;
      width: 100%;
    }

    .footer-content{
      display: flex;
      flex-direction: column;
      padding: 20px 20px 20px;
      max-width: 100rem;
      min-height: 45rem;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;

      .description{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        height: 28rem;
      }

    }

      main {
        max-width: 39rem;
        order: 1;

        img {
          border-radius: 15px;
        }
      }
    }

    h1 {
      text-align: center;
    }

    button {
      margin: 1rem 0;
      height: 6.3rem;
      width: 24.2rem;
      border-radius: 8px;
      order: 1;
    }

    .divider{
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
      height: 1px;
      background: #E1E1E1;
    }

    .aside-right-side{
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .text {
      display: flex;
      flex-direction: column;
      align-items: start;
      z-index: 10;
      
      h1 {
        font-size: 2.4rem;
        font-weight: 500;
      }
      p {
        font-family: 'Open Sans', sans-serif;
        margin: 1.5rem 0 0rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 300;
      }
    }
/*
    .bottom-shape {
      position: absolute;
      top: -12vw;
      left: 0;
    } */

    ${media.greaterThan('medium')`
      padding-bottom: 5rem;

      .content {
        flex-direction: row;
        justify-content: space-between;
        gap: 2rem;
        max-width: 1270px;
        margin: auto 64px;

        .title-content{
          font-size: 3.5rem;
          padding: 0 3rem;
          max-width: 85rem;
          text-align: center;
        }
      }

      .main-video-content {
        flex-direction: row;
        gap: 3rem;
        margin-top: 0;
      }

      main {
        max-width: 60rem;
        order: 0;
      }

      .footer-content{
        display: flex;
        flex-direction: column;
        padding: 20px 20px 20px;
        max-width: 100rem;
        min-height: 35rem;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;

        .description{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          text-align: left;
          width: 95rem;

        }

      }

      .text {
        align-items: flex-start;
        justify-content: center;

      h1 {
        text-align: left;
      }

      p {
        margin: 0rem;
        text-align: left;
        font-size: 1.6rem;
      }

        button {
          margin-top: 0;
          height: 7.3rem;
          width: 23.7rem;
          border-radius: 16px;
      } */

        }
      }

      /* .bottom-shape {
        position: absolute;
        top: -10vw;
        left: 0;
      } */
    `}
  `}
`

export const VideoList = styled.aside`
  display: flex;
  flex-direction: column;
  gap: 24px;
  order: 1;

  .video {
    max-width: 247px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 124px;
      max-height: 127px;
      border-radius: 16px;
      filter: brightness(0.5);
    }

    .description {
      margin-top: 6px;
      text-align: center;
      order: 2;
      span,
      p {
        font-family: 'Open Sans', sans-serif;
        text-align: center;
        font-size: 2rem;
      }
    }
  }

  ${media.greaterThan('medium')`
     margin-bottom: 0;
      .video {
        .description {
        margin-top: 4px;
          span, p {
            font-size: 1.8rem;

          }
        }
      }
  `}
`

export const MidBannerContainer = styled.section`
  ${({ theme }) => css`
    position: relative;
    margin-top: -10vw;
    padding-bottom: calc(1500 / 1200 * 100%);
    background-color: ${theme.colors.lightPink};
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%);

    ${media.greaterThan('medium')`
      padding-bottom: 0;
      background-color: ${theme.colors.white};
    `}
  `}
`
export const MidBanner = styled.div`
  padding-bottom: calc(1700 / 1440 * 100%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);

  background-image: url(${midBanner});
  background-size: cover;
  background-position: left top;
  background-repeat: no-repeat;

  ${media.greaterThan('medium')`
    padding-bottom: calc(894 / 1440 * 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 80%);
  `}
`

export const WhiteBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 90%;
    padding: 2rem;
    position: absolute;
    background-color: ${theme.colors.white};

    left: 0;
    right: 0;
    margin: -10rem auto 0 auto;

    border-radius: 0.8rem;
    box-shadow: 0px 6px 43px #00000029;
    text-align: center;

    p {
      margin: 1.5rem 0;
    }

    ${media.greaterThan('medium')`
      width: 50rem;
      height: 40rem;

      align-items: flex-start;
      justify-content: center;

      text-align: left;
      left: unset;
      right: 8vw;
      bottom: 10vw;
      margin: 0;
    `}
  `}
`

export const InfluencersContainer = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    > p {
      padding: 0 3rem;
      margin: 2rem auto 0 auto;
      max-width: 70rem;
      text-align: center;
      font-size: 1.4rem;
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
    }

    > h1 {
      padding: 0 3rem;
      max-width: 65rem;
      font-size: 2.2rem;
      text-align: center;
    }

    ${media.greaterThan('medium')`
      margin-top: 42px;
      margin-bottom: 42px;

      .bottom-shape {
        position: absolute;
        bottom: 5px;
        margin-bottom: -10vw;
      }

      > p {
        margin: 2rem auto 4rem auto;
        font-size: 1.6rem;
        max-width: 90rem;
      }

      h1 {
        font-size: 2.8rem;
      }
    `}
  `}
`
export const Influencers = styled.div`
  width: 100%;
  max-width: 100rem;
  z-index: 1;

  margin-top: 13px;

  ${media.greaterThan('medium')`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    max-width: 130rem;

    main,svg{
      margin: 0 5rem;
      width: 25%;
    }
  `}
`

export const PinkBannerContainer = styled.section`
  ${({ theme }) => css`
    background-color: ${theme.colors.lightPink};

    .content {
      padding: 10vw 0 5rem 0;
      display: flex;
      gap: 20px;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;

      .subscribe {
        margin-top: 1rem;
        width: 100%;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 5vw;
      margin-top: 2rem;
    }

    h1 {
      text-align: center;
      font-size: 2.5rem;
      font-weight: 600;
    }

    p {
      text-align: center;
      margin: 1rem 0;
      font-size: 1.4rem;
      font-family: 'Open Sans', sans-serif;
      line-height: 21.79px;
    }

    img {
      width: 90%;
      max-width: 51rem;
      border-radius: 12px;
    }

    a {
      text-decoration: none;
    }

    ${media.greaterThan('medium')`

    .content {
        padding: 2vw 5vw 1rem 5vw;
        flex-direction: row;
        justify-content: space-around;
        gap: 5px;

        .subscribe {
          width: fit-content;
          margin: 1rem auto 0;
        }
      }

      .bottom-shape {
        margin-top: 10vw;
        margin-bottom: -10vw;
      }

      div {
        align-items: flex-start;
        padding: 0;
        max-width: 48rem;
      }

      h1 {
        font-size: 2.8rem;
        text-align: center;
        margin: 0 auto;
      }

      p {
        text-align: center;
        margin: 1rem 0;
        font-size: 1.6rem;
        font-weight: 300;
      }

      img {
        max-width: 70rem;
        width: 605px;
        height: 350px;
       }

      a {
        text-decoration: none;
        button {
          width: 31.4rem;
        }
      }
    `}
  `}
`

export const FaqContainer = styled.section`
  ${({ theme }) => css`
    margin: 4rem auto 0 auto;
    padding: 2rem;
    max-width: 140rem;
    h1 {
      margin: 0 0 20px 0;
      max-width: 40.6rem;
      text-align: center;
    }

    a {
      margin-top: 2rem;
      margin-bottom: 6rem;
      color: ${theme.colors.darkPink};
      font-family: 'Open Sans', sans-serif;
      font-size: 1.6rem;
      font-weight: 600;

      &:hover {
        opacity: 0.8;
      }
    }

    .questions {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ${media.greaterThan('medium')`
      margin: 6rem auto 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      h1 {
        max-width: 38.4rem;
        font-size: 2.8rem;
        text-align: center;
      }

      .questions {
        width: 80%;
      }

    `}
  `}
`

export const LastEditionBottom = styled.div`
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 5rem;
  background-color: #f7f0fe;
  z-index: -1;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  h1 {
    font-size: 2.2rem;
  }

  p {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.4rem;
    margin-top: 1rem;
    margin-bottom: 2.1rem;
  }

  img {
    width: 100%;
  }

  .button-container {
    button {
      margin-top: 2rem;
    }
  }

  ${media.greaterThan('medium')`
    padding-top: 6rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    >div {
      align-items: flex-start;
      padding: 0 0 2rem 0rem;
      text-align: center;
      max-width: 47rem;
      h1 {
        font-size: 2.8rem;
      }
      p {
        margin-top: 1rem;
        font-size: 1.6rem;
        font-weight: 300;
      }
    }
  `}
`

export const SocialContainer = styled.section`
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 10vw;

  .dont-miss {
    width: 100%;
  }

  p {
    text-align: center;
    margin: 3rem 0;
  }

  .text {
    max-width: 39rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .social {
    display: flex;
    justify-content: space-between;

    img {
      width: 80%;
    }
  }

  ${media.greaterThan('medium')`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 25rem;

    margin-top: 6rem;
    padding: 0;

    p {
      text-align: left;
    }

    .social {
      padding-bottom: 6rem;
    }

    .dont-miss {
      width: 384px;
      height: 231px;
    }

    .text {
      align-items: center;
      width: 600px;
      > p {
        text-align: center;
        font-size: 1.6rem;
      }
    }
  `}
`

export const VideoSliderContainer = styled.section`
  ${media.greaterThan('medium')`
    width: 100%;
  `}
`

export const Modal = styled.section`
  ${({ theme }) => css`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    z-index: ${theme.layers.modal};

    svg {
      cursor: pointer;
      width: 5rem;
      position: absolute;
      right: 2rem;
      top: 2rem;
      fill: ${theme.colors.white};
    }

    img {
      width: 100%;
      opacity: 0;
    }

    iframe {
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .content {
      margin-top: 10vh;
      position: relative;
      width: 100%;
      max-width: 90rem !important;
    }

    ${media.greaterThan('medium')`
      svg {
        right: 5vw;
        top: 5vw;
      }
    `}
  `}
`

export const PrizeSection = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    background-color: ${theme.colors.white};
    padding-bottom: 20px;

    .bottom-shape {
      position: absolute;
      top: -12vw;
      left: 0;
    }

    .subscribe {
      margin: auto;
      text-decoration: none;
    }

    ${media.greaterThan('medium')`
      padding-bottom: 42px;
      .bottom-shape {
        position: absolute;
        top: -7vw;
        left: 0;
        width: 100%;
      }
    `}
  `}
`

export const PrizeContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    position: relative;
    padding-top: 2.5rem;
    min-height: 346px;
    background-color: ${theme.colors.purple};

    .prize-content {
      max-width: 885px;
      padding: 0px 15px;

      h1 {
        color: ${theme.colors.white};
        text-align: center;
        margin-bottom: 1rem;
        font-size: 2.2rem;
      }

      p {
        color: ${theme.colors.white};
        font-size: 1.4rem;
        line-height: 16.34px;
        text-align: center;
      }
    }

    @media (max-width: 350px) {
      padding-top: 3.5rem;
      .prize-content {
        position: relative;
        top: -1.25rem;
      }
    }

    ${media.greaterThan('medium')`
      padding-top: 1.25rem;
      min-height: 300px;
      .prize-content {
        h1 {
          text-align: center;
          font-size: 2.8rem;
        }
        p {
          font-size: 1.6rem;
        }
      }

      p {
        text-align: center;
      }
      }
    `}
  `}
`

export const PrizeBox = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
    gap: 24px;

    margin: -205px auto 64px;
    z-index: 1;
    padding: 32px 16px;
    width: 100%;
    max-width: calc(100% - 30px);
    box-shadow: 0px 13px 33px #00000029;
    border-radius: 8px;
    background-color: ${theme.colors.white};

      img {
        width: 126px;
        height: 158px;
        object-fit: contain;
      }

      ${media.greaterThan('medium')`
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 304px;
        gap: 32px;
        margin: -150px auto 64px;

        box-shadow: 0px 13px 33px #00000029;
        max-width: 100%;

        img {
          width: 195.7px;
          height: 245px;
      }
  `}

  ${media.greaterThan('large')`
        max-width: calc(100% - 60px);
  `}

  ${media.greaterThan('huge')`
        max-width: 1270px;
  `}
   }
`}
`

export const PhasesSection = styled.section`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    padding: 24px;

    .phase-header {
      max-width: 646px;
      text-align: center;

      h1 {
        font-size: 2.8rem;
      }

      p {
        margin: 2rem auto 0 auto;
      }
    }

    a {
      text-decoration: none;
    }

    ${media.greaterThan('medium')`
    .phase-header {
      max-width: 800px;
      text-align: center;
    }
    `}
  `}
`
export const PhasesBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 32px;
    padding: 42px 20px 84px;
    margin: 1.8rem 0 3rem;

    width: 100%;
    max-width: 1270px;

    box-shadow: 0px 13px 33px #00000029;
    border-radius: 16px;

    background-color: ${theme.colors.white};

    .phase {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 356px;

      .phase-img-header {
        width: 118px;
        height: 117px;
        object-fit: contain;
      }

      .phase-img {
        width: 115px;
        height: 39px;
      }

      strong {
        font-size: 1.6rem;
        margin: 1rem 0 0;
        font-weight: 500;
        text-align: center;
      }

      span {
        display: block;
        font-size: 1.4rem;
        font-weight: 300;
        text-align: center;
        margin: 0.1rem 0 0.6rem;
      }

      p {
        font-family: "Open Sans", sans-serif;
        font-size: 1.4rem;
        font-weight: 300;
        text-align: center;
        max-width: 29rem;
        line-height: 2rem;
      }
    }

      ${media.greaterThan('medium')`
        flex-direction: row;
        align-items: baseline;
        min-height: 406px;
        padding: 42px 42px 84px;

        box-shadow: 0px 13px 33px #00000029;
        border-radius: 16px;

        .phase {
          .phase-img-header {
            width: 197px;
            height: 196px;
          }
  
          .phase-img {
            width: 147px;
            height: 50px;
          }

          strong {
            font-size: 2.4rem;
          }
  
          span {
            font-size: 1.6rem;
          }
  
          p {
            font-size: 1.6rem;
            max-width: 31rem;
          }
        }

        
      }
  `}
   }
`}
`

export const PhasesDescription = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 775px;

    p {
      margin: 1.5rem 0 3rem 0;
      text-align: center;
    }

    a {
      color: ${theme.colors.red};
      font-weight: ${theme.font.bold};

      transition: all 0.2s ease;

      &:hover {
        filter: brightness(0.9);
      }
    }

    ${media.greaterThan('medium')`
      margin-top: 7rem;
    `}
  `}
`

export const DividerPhase = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.lightGray};
    border-radius: 50px;
    opacity: 1;

    align-self: center;
    margin-bottom: 5.5rem;

    width: 100px;
    height: 6px;
  `}
`

export const Phase = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 400px;
    position: relative;

    @media (min-width: 768px) {
      &:not(:last-child)::after {
        content: '';
        background: #eaeaea;
        border-radius: 50px;
        opacity: 1;
        display: block;
        width: 100%;
        max-width: 100px;
        height: 6px;

        position: absolute;
        top: 145px;
        left: calc(100% - 37.5px);
      }
    }

    img {
      width: 140px;
    }

    p {
      max-width: 250px;
      margin-top: 24px;

      text-align: center;
      font-size: ${theme.font.sizes.small};
    }
  `}
`

export const ChallengeWinnersSection = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 4rem auto;

    > p {
      padding: 0 3rem;
      margin: 2rem auto 0 auto;
      max-width: 1020px;
      text-align: center;
      font-size: 1.4rem;
    }

    > h1 {
      padding: 0 3rem;
      max-width: 85rem;
      text-align: center;
      font-size: 2.2rem;
    }

    a {
      widht: 100%;
      text-decoration: none;
      button {
        width: 300px !important;
      }
    }

    ${media.greaterThan('medium')`
      margin-bottom: 5rem;

      > p {
        margin: 2rem auto 4rem auto;
        font-size: 1.6rem
      }

    >  h1 {
        font-size: 2.8rem;
      }
  `}
  `}
`

export const ChallengeWinnersButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin: 30px 0 10px;

  > button {
    width: 16.1rem;
    height: 4.428rem;
    font-size: 1.3rem;
    line-height: auto;
  }

  ${media.greaterThan('medium')`
    gap: 30px;
    margin: 20px;
    > button {
      height: 7.3rem;
      width: 23.7rem;
      font-size: 1.7rem;
    }
`}
`

export const ChallengeWinnersContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 32px;
  overflow-x: hidden;

  padding: 5rem 1.5rem;
  border-radius: 16px;

  .winner-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${media.greaterThan('medium')`
    padding: 3.5rem 4rem;
    gap: 32px;
`}
`

export const Winner = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 15px;
      text-decoration: none;
      color: ${theme.colors.darkGray};
      text-align: center;
      img {
        width: 156px;
        height: 91px;
        object-fit: cover;
        margin-bottom: 10px;

        border-radius: 20px;
        transition: 0.3s ease-in-out;
      }

      &:hover {
        img {
          opacity: 0.8;
        }
      }
    }

    ${media.greaterThan('medium')`
      a {
        font-size: ${theme.font.sizes.medium};
        img {
        width: 206px;
        height: 126px;
        }
      }

    `}
  `}
`
export const ChallengeSliderContainer = styled.section`
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: start;
  ${media.greaterThan('medium')`
   .title-challenge {
      font-family: "Open Sans", sans-serif;
      font-size: 1.8rem;
      font-weight: 500;
    }
    width: 550px;
  `}
`

export const TermsAndConditions = styled.section`
  ${({ theme }) => css`
    width: 100%;
    padding: 5rem 2.2rem;
    background-color: #f7f0fe;

    p {
      text-align: center;
      font-size: 1.6rem;
      a {
        font-size: inherit;
        color: ${theme.colors.black};
        font-weight: ${theme.font.semiBold};

        &:hover {
          opacity: 0.8;
        }
      }
    }
  `}
`

export const ChallengesFinalistsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5rem;
    width: 100%;
    padding: 2.2rem 2.2rem 5rem;
    margin-top: 1.6rem;

    figure {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 475px;
      img.avatar {
        border-radius: 50%;
      }
      figcaption {
        min-height: 105px;
      }
      h2 {
        margin-top: 1.2rem;
        text-align: center;
        font-size: 1.6rem;
      }
      .position {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        background: #dac6fc;
        border-radius: 12px;
        width: 90px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-top: 22px;
      }
      img:not(.avatar) {
        margin-bottom: 7px;
      }
      .white-space {
        min-height: 50px;
      }
      a {
        font-size: inherit;
        color: ${theme.colors.black};
        font-weight: ${theme.font.semiBold};

        &:hover {
          opacity: 0.8;
        }
      }
    }
  `}
`

export const EventVideoContainer = styled.div`
  ${({ theme }) => css`
    .event-video {
      width: 95vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      h1 {
        padding: 0 3rem;
        max-width: 85rem;
        text-align: center;
        font-size: 2.8rem;
        margin-bottom: 30px;
      }
      iframe {
        margin: 0 auto;
      }
      @media (max-width: 768px) {
        iframe {
          width: 340px;
          height: 600px;
        }
      }
    }
  `}
`
