import * as S from './style'
import Slider, { SliderSettings } from 'components/Slider'

import number1 from 'assets/img/next-step/card-bottom1.webp'
import number2 from 'assets/img/next-step/card-bottom2.webp'
import number3 from 'assets/img/next-step/card-bottom3.webp'
import number4 from 'assets/img/next-step/card-bottom4.webp'
import NextStepCardsBottom from 'components/NextStepCardsBottom'

const settings: SliderSettings = {
  slidesToShow: 1,
  infinite: false,
  lazyLoad: 'progressive',
  centerMode: true,
  dots: true,
  centerPadding: '150px',
  arrows: false,
  touchThreshold: 100,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        centerPadding: '100px',
        arrows: false,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        centerPadding: '40px',
        arrows: false,
        slidesToShow: 1
      }
    }
  ]
}

export default function NextStepBottomSlider() {
  return (
    <S.Wrapper>
      <Slider settings={settings}>
        <NextStepCardsBottom
          isSlideItem
          image={number1}
          title="Recebidos Todo Mês!"
          text="Você recebe todo mês uma box com R$220 em produtos e ainda tem descontos e frete grátis na Loja da Glambox. <br/> É a oportunidade perfeita de gravar conteúdos criativos mostrando seus “recebidos” nas redes sociais."
        />
        <NextStepCardsBottom
          isSlideItem
          image={number2}
          title="Conteúdos Exclusivos"
          text="Acesse tutoriais e cursos variados sobre lifestyle, beleza e o melhor: como bombar o seu Instagram para alavancar sua carreira de influencer."
          video="https://player.vimeo.com/video/475214752?quality=540p"
        />
        <NextStepCardsBottom
          isSlideItem
          image={number3}
          title="Participe do SUPER evento!"
          text="As 10 Glamgirls mais engajadas com os challenges do Glamstar serão selecionadas para participar do nosso evento em São Paulo. As 5 melhores terão tudo pago por nós!"
        />
        <NextStepCardsBottom
          isSlideItem
          image={number4}
          title="Glamstar em <br/> Primeira Mão!"
          text="Mesmo como GlamGirl, você pode participar de todos os challenges da competição. Diferente das inscrições oficiais, você não ganha a competição, mas você ganha Glampoints e constrói sua presença nas redes sociais para participar do Glamstar 2023!"
        />
      </Slider>
    </S.Wrapper>
  )
}
