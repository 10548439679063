import theme from 'styles/theme'

type NextShapeProps = {
  topMobile?: boolean
  topDesk?: boolean
  upSideDown?: boolean
  color: 'lightPink' | 'white' | 'midWhite' | 'purple' | undefined
  className?: string
}

export default function NextStepShape({
  topMobile,
  topDesk,
  upSideDown,
  color,
  className
}: NextShapeProps) {
  return (
    <>
      {topMobile && (
        <svg
          className={className}
          viewBox="0 0 375 347"
          style={{ transform: upSideDown ? 'scale(-1, -1)' : 'scale(1)' }}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M375 40C146 40 154 -38 0 25V346.5H375V40Z"
            fill={theme.colors[color!]}
          />
        </svg>
      )}

      {topDesk && (
        <>
          {color ? (
            <svg
              className={className}
              viewBox="0 0 1366 340"
              fill={theme.colors[color]}
              style={{ transform: upSideDown ? 'scale(-1, -1)' : 'scale(1)' }}
              xmlSpace="preserve"
            >
              <path
                d="M1366 82.9128C747 82.9128 650.5 -58.757 0 28.2728V339.5H1366V82.9128Z"
                fill="#A044F1"
              />
            </svg>
          ) : (
            <svg
              className={className}
              viewBox="0 -10 1366 340"
              style={{ transform: upSideDown ? 'scale(-1, -1)' : 'scale(1)' }}
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
            >
              <path
                d="M1366 82.9128C747 82.9128 650.5 -58.757 0 28.2728V339.5H1366V82.9128Z"
                fill="#A044F1"
              />
            </svg>
          )}
        </>
      )}
    </>
  )
}
