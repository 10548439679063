import styled from 'styled-components'
import media from 'styled-media-query'
import { LastEditionProps } from '.'

export const Wrapper = styled.main<Pick<LastEditionProps, 'playerSize'>>`
  width: 100%;
  .videos_page {
    display: block;
    text-align: center;

    ${media.greaterThan('medium')`
      padding: 1rem;
    `}
  }
  .videos_page section {
    display: block;
    width: 100%;
    height: auto;
    position: relative;
    outline: none !important;
  }
  .videos_page section svg#play {
    width: ${({ playerSize }) => (playerSize === 'small' ? '50px' : '100px')};
    margin: 0 auto;
    display: block;
    cursor: pointer;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
  }
  .videos_page section svg#play #triangle {
    fill: rgba(22, 22, 22, 0);
    transition: 500ms;
    transform-origin: 50% 50%;
    stroke: #ffffff;
  }
  .videos_page section:hover svg#play #triangle {
    fill: #fff;
    transform-origin: 50% 50%;
  }
  .videos_page section svg #lineOne,
  .videos_page section svg #lineTwo {
    transform-origin: 50% 50%;
    transition: 1s;
    stroke: #ffffff;
  }
  .videos_page section:hover svg #lineOne {
    transform: rotate(260deg);
    transform-origin: 50% 50%;
  }
  .videos_page section:hover svg #lineTwo {
    transform: rotate(-450deg);
    transform-origin: 50% 50%;
  }
  .videos_page section img {
    filter: brightness(0.5);
    display: block;
    width: 100%;
    margin: auto;
    max-width: ${({ playerSize }) =>
      playerSize === 'small' ? '345px' : '592px'};
    height: 194px;
    object-fit: cover;

    ${media.greaterThan('medium')`
      height: 333px;
    `}
  }
`
