type SubscriptionShapeProps = {
  mobile: boolean
}

export default function SubscriptionShape({ mobile }: SubscriptionShapeProps) {
  return (
    <>
      {mobile ? (
        <svg viewBox="30 0 400 200" xmlSpace="preserve">
          <path
            d="M24.1 4.7S100.8 5.9 158.3 15c0 0-50.5-7.9-134.2-3.5V4.7z"
            fill="#ff0017"
          />
          <path
            d="M24.1 10.5c31-1.9 76.3-2.8 130.5 3.1 33.9 3.7 62.8 9.6 119.2 21.3 71.9 14.9 128.8 26.9 164.3 34.3V1080h-414V10.5z"
            fill="#a044f1"
          />
        </svg>
      ) : (
        <svg viewBox="70 0 1360 300" xmlSpace="preserve">
          <path
            d="M69.4 145.5c158.3-72.7 397.7-125 588.2-115.2 81.2 4.2 157.1 11.8 157.1 11.8 101 10.1 138.5 18.9 245.6 28.3 64.6 5.7 96.9 8.5 127.5 8.3 65.6-.6 150-10.5 247.5-46.1v915.3l-1366 5.9V145.5z"
            fill="#a044f1"
          />
          <path
            d="M69.4 145.5C243.5 72.1 448.3 21 675 31.2c17.9.8 35.6 2 53 3.5-52.2-6.7-109.2-10.9-170.3-10.9-238.9 0-367.4 45.9-488.3 92.5v29.2z"
            fill="#f5220f"
          />
        </svg>
      )}
    </>
  )
}
